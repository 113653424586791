import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import TextField from '@material-ui/core/TextField';

import {ReactComponent as PlusIcon} from '../styles/imgs/plus-icon.svg';
import {ReactComponent as DeleteIcon} from '../styles/imgs/deleteIcon.svg';
import {ReactComponent as CheckboxRound} from '../styles/imgs/checkbox-round.svg';
import {ReactComponent as CheckboxRoundFull} from '../styles/imgs/checkbox-round-full.svg';
import {ReactComponent as EmployerIcon} from '../styles/imgs/employerIcon.svg';

class AditionalInputsTemplateHousehold extends Component {
    
    addRowHousehold = (event, stateObjName, round) => {this.props.addRowHousehold(event, stateObjName, round)}
    handleNameHousehold = (event, index,stateObjName, round) =>{this.props.handleNameHousehold(event, index, stateObjName, round)}
    handleAmountHousehold = (event, index, stateObjName, round) =>{ this.props.handleAmountHousehold(event, index, stateObjName, round)}
    removeRowHousehold = (index, stateObjName, round) => {this.props.removeRowHousehold(index,stateObjName,round)}
    
    render() { 
        let { stateObj, stateObjName, stateElementVal, stateElement, nameLabel, namePlaceholder,  amountLabel,
         monthyAmountPlaceholder, addText, round} = this.props;    
            
        return ( 

        <React.Fragment> 
           
            {stateElementVal=='yes' && stateObj.map((value, index)=> {
                return ( 
                   <div className="row border-b own-checkbox" key={index}>
                        <Grid container spacing={0} > 
                            <Grid container item xs={1} alignItems="center" tabIndex="0" justify="center" className="icon-additional round-icon-w"> 
                            {
                                    stateElement == 'employed'
                                    ? <EmployerIcon className={stateObj[index].name&&stateObj[index].value ? 'correctIcon':''} />
                                    :<div><CheckboxRound className={stateObj[index].name&&stateObj[index].value ? 'hidden':''}/>
                                    <CheckboxRoundFull className={stateObj[index].name&&stateObj[index].value ? 'correctIcon':'hidden'}/></div>
                                }
                            </Grid>  
                            <Grid container item xs={5} justify="center"> 
                                <TextField    
                                    label={nameLabel + ' '+ (index>0 ?  index+1:'')}
                                    placeholder={namePlaceholder}
                                    onChange={(event)=>this.handleNameHousehold(event, index, stateObjName, round)}
                                    name="name" 
                                    className={'full-width padding-tb '+(stateElement !== 'employed'? 'big-label':'')}
                                    type="text"  
                                    value={stateObj[index].name} 
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />  
                            </Grid> 
                            <Grid container item xs={5} justify="center">
                                <TextField   
                                    label={amountLabel}
                                    placeholder={monthyAmountPlaceholder}
                                    onChange={(event)=>this.handleAmountHousehold(event, index, stateObjName, round)}
                                    name="value" 
                                    className={'full-width padding-tb'}
                                    type="number"  
                                    value={stateObj[index].value} 
                                    InputProps={{ 
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            $
                                            </InputAdornment>
                                        ),
                                    }}
                                /> 
                            </Grid>  
                            <Grid container item xs={1} justify="center" alignItems="center">
                                {stateObj.length -1 == index &&
                                    <DeleteIcon className="cursor"
                                    onClick={() => this.removeRowHousehold(index, stateObjName, round)}
                                    />
                                }
                            </Grid>  
                        </Grid> 
                    </div>  
                   )
                }
                
            )
        }
            { stateElementVal=='yes' && 
                <div className="row cursor border-b addText-wrapper" onClick={(event) => this.addRowHousehold(event, stateObjName,round)}>
                    <Grid container spacing={0} className="padding-tb-eq"> 
                        <Grid container item xs={1} alignItems="center" justify="center">
                            <PlusIcon className="correctIcon"/>
                        </Grid>  
                        <Grid container item xs={11} justify="flex-start"> 
                                <p className="addText"> {addText}</p>
                        </Grid> 
                    </Grid> 
                </div>  
            }   
            </React.Fragment> 
         );

         
    }
}
 
export default AditionalInputsTemplateHousehold;