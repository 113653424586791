import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import TopSection from "../../Components/TopSection";
import FooterProgressBarDocuments from "../../Components/FooterProgressBarDocuments";

import Signature from "../Documents/Signature";
import Photos from "../Documents/Photos";
import Contacts from "../Documents/Contacts";

class MainWrapperDocuments extends Component {
  saveSignature = (img) => {
    this.props.saveSignature(img);
  };
  clearignature = () => {
    this.props.clearignature();
  };

  handlePhotoSend = (event) => {
    this.props.handlePhotoSend(event);
  };
  removePhoto = (name) => {
    this.props.removePhoto(name);
  };

  handleChangeContacts = (event) => {
    this.props.handleChangeContacts(event);
  };

  handleRowClickContacts = (inputName, stateForUpdate, val) => {
    this.props.handleRowClickContacts(inputName, stateForUpdate, val);
  };

  sendToApi = (stateElement, obj, cb, sendFiles) => {
    this.props.sendToApi(
      stateElement,
      obj,
      cb,
      sendFiles,
      this.props.location.pathname === "/documents/step1",
      () => this.props.history.push("/documents/step2")
    );
  };

  returnView = (propsObj) => {
    const { view } = this.props.stepData;

    if (view == "signature") {
      return (
        <Signature
          data={propsObj}
          saveSignature={this.saveSignature}
          clearignature={this.clearignature}
        />
      );
    } else if (view == "photos") {
      return (
        <Photos
          data={propsObj}
          handlePhotoSend={this.handlePhotoSend}
          removePhoto={this.removePhoto}
          confirmNewDocuments={this.props.confirmNewDocuments}
          showConfirmNewDocuments={this.props.showConfirmNewDocuments}
          closeConfirmNewDocuments={this.props.closeConfirmNewDocuments}
        />
      );
    } else if (view == "contacts") {
      return (
        <Contacts
          data={propsObj}
          handleChangeContacts={this.handleChangeContacts}
          handleRowClickContacts={this.handleRowClickContacts}
        />
      );
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      altLang,
      logoUrl,
      setLangCode,
      languageCode,
      loading,
      confirmNewDocuments,
      showConfirmNewDocuments,
      closeConfirmNewDocuments,
    } = this.props;

    let propsObj = {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      altLang,
      logoUrl,
    };

    return (
      <React.Fragment>
        <TopSection
          documents="true"
          maxSteps="02"
          data={propsObj}
          setLangCode={setLangCode}
          languageCode={languageCode}
        />

        {this.returnView(propsObj)}

        <FooterProgressBarDocuments
          loading={loading}
          sendToApi={this.sendToApi}
          data={propsObj}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MainWrapperDocuments);
