import React, { Component } from "react";

import GiStepCheckBox from "./GIStepCheckBox";
import GiStepGender from "./GIStepGender";
import FamilyMembers from "./FamilyMembers";
import HouseHoldTotalIncome from "./HouseHoldTotalIncome";
import Milestone from "../Milestone";

import TopSection from "../../Components/TopSection";
import FooterProgressBar from "../../Components/FooterProgressBar";

class MainWrapper extends Component {
  handleCheckboxClick = (state, value) => {
    this.props.handleCheckboxClick(state, value);
  };

  handleFamilyMemberChange = (event) => {
    this.props.handleFamilyMemberChange(event);
  };

  increaseFamilyMember = (value, name) => {
    this.props.increaseFamilyMember(value, name);
  };

  decreaseFamilyMember = (value, name) => {
    this.props.decreaseFamilyMember(value, name);
  };

  handleChangeSilder = (event, newValue) => {
    this.props.handleChangeSilder(event, newValue);
  };

  sendToApi = (stateElement, obj, cb) => {
    this.props.sendToApi(stateElement, obj, cb);
  };

  returnView = (propsObj) => {
    const { view } = this.props.stepData;

    if (view == "gender") {
      return (
        <GiStepGender
          data={propsObj}
          handleCheckboxClick={this.handleCheckboxClick}
        />
      );
    } else if (view == "familyMembers") {
      return (
        <FamilyMembers
          data={propsObj}
          handleFamilyMemberChange={this.handleFamilyMemberChange}
          increaseFamilyMember={this.increaseFamilyMember}
          decreaseFamilyMember={this.decreaseFamilyMember}
        />
      );
    } else if (view == "houseHoldTotalIncome") {
      return (
        <HouseHoldTotalIncome
          data={propsObj}
          handleChangeSilder={this.handleChangeSilder}
        />
      );
    } else if (view == "milestone") {
      return (
        <Milestone
          data={propsObj}
          handleChangeSilder={this.handleChangeSilder}
        />
      );
    } else {
      return (
        <GiStepCheckBox
          data={propsObj}
          handleCheckboxClick={this.handleCheckboxClick}
        />
      );
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      landData,
      stepData,
      stateData,
      history,
      personData,
      altLang,
      logoUrl,
      setLangCode,
      languageCode,
      loading,
    } = this.props;

    let propsObj = {
      landData,
      stepData,
      stateData,
      history,
      personData,
      altLang,
      logoUrl,
    };

    return (
      <React.Fragment>
        <TopSection
          maxSteps="08"
          data={propsObj}
          genderalInfo="true"
          setLangCode={setLangCode}
          languageCode={languageCode}
        />
        {this.returnView(propsObj)}

        <FooterProgressBar
          loading={loading}
          sendToApi={this.sendToApi}
          data={propsObj}
        />
      </React.Fragment>
    );
  }
}

export default MainWrapper;
