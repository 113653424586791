import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";

import Logo from "./Logo";
import StepsCounter from "./StepsCounter";

class CheckboxDropdown extends Component {
  render() {
    const { data, maxSteps, houseHold, setLangCode, languageCode } = this.props;

    const {
      landData,
      stepData,
      stateData,
      globalStateData,
      altLang,
      logoUrl,
    } = data;
    const {
      mainTitle,
      intoText,
      mainTitleBefore,
      subTitle,
      leadText,
      memberIndex,
      memberOptions,
    } = landData;
    const { stepCounter, view, round } = stepData;
    const { Color } = altLang;

    return (
      <React.Fragment>
        <div
          className={
            "top-section " +
            (view !== "assets" && view !== "photos" ? "bottom-space " : "") +
            (view == "milestone" || view == "contacts"
              ? "bottom-space-small"
              : "")
          }
        >
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
                {view !== "milestone" && (
                  <StepsCounter step={stepCounter} maxStep={maxSteps} />
                )}
              </Grid>
            </div>
          </header>
          <div className="row">
            {houseHold ? (
              view !== "memberData" ? (
                <h2 className="section-title full-width">
                  <span>
                    {mainTitleBefore}{" "}
                    {view !== "milestone" &&
                      stateData[round].personData.firstName}{" "}
                    {mainTitle}
                  </span>
                </h2>
              ) : (
                view == "memberData" && (
                  <h2 className="section-title full-width">
                    <span>{mainTitleBefore} </span>
                    <span>
                      {globalStateData.houseHoldInfo[round].personData
                        .relationship == "Spouse" && memberOptions.spouse}
                      {globalStateData.houseHoldInfo[round].personData
                        .relationship == "Child" && memberOptions.child}
                      {globalStateData.houseHoldInfo[round].personData
                        .relationship !== "Child" &&
                        globalStateData.houseHoldInfo[round].personData
                          .relationship !== "Spouse" &&
                        memberOptions.other}
                    </span>
                  </h2>
                )
              )
            ) : (
              <h2 className="section-title full-width">
                <span>
                  {leadText && <br /> ? leadText : ""}
                  {leadText && <br />}
                  {mainTitle}
                </span>
              </h2>
            )}

            {intoText && <p className="text-intro">{intoText}</p>}
            {subTitle && <h4 className="special-title">{subTitle}</h4>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckboxDropdown;
