import React, { Component } from 'react'; 

import CheckboxDropdown from '../../Components/CheckboxDropdown'
import ChecBoxRowTemplate from '../../Components/CheckBoxRowTemplate'
import AditionalInputsTemplate from '../../Components/AditionalInputsTemplate'

class TaxReturn extends Component { 
    handleCheckboxDropdown = (state, value) =>{this.props.handleCheckboxDropdown(state, value)}   
    handleIncomeChange=(event,stateForUpdate) => {this.props.handleIncomeChange(event,stateForUpdate)}

    handleRowClick = (inputName,stateForUpdate,val) => {this.props.handleRowClick(inputName,stateForUpdate,val)}

    addRow = (event, stateObjName) => {this.props.addRow(event, stateObjName)}
    handleName = (event, index,stateObjName) =>{this.props.handleName(event, index, stateObjName)}
    handleAmount = (event, index,stateObjName) =>{ this.props.handleAmount(event, index, stateObjName)}
    removeRow = (index,stateObjName) => {this.props.removeRow(index,stateObjName)}


    render() { 
        const {landData, stepData, stateData} =  this.props.data 
      
        
        const {alimony, studentLoanInterest, childcare, monthyAmountText, monthyAmountPlaceholder, others,othersPlaceholder, addText} = landData; 
        const {stateElement, stateObjName} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal, expenseList} = stateData;
      
        
        let propsObj = {landData, stepData, stateData}
         
        return ( 
            <React.Fragment>  
                <CheckboxDropdown 
                data = {propsObj} 
                handleCheckboxDropdown = {this.handleCheckboxDropdown} />
                {stateElementVal=='yes' &&
                        <div>
                            <ChecBoxRowTemplate 
                                text={alimony}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='alimony'
                                inputValue = {expenseList.alimony} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            /> 
                            <ChecBoxRowTemplate 
                                text={childcare}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='childcare'
                                inputValue = {expenseList.childcare} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            />    
                            <ChecBoxRowTemplate 
                                text={studentLoanInterest}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='studentLoanInterest'
                                inputValue = {expenseList.studentLoanInterest} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            />     
                         
                        </div>      
                    }
                    <AditionalInputsTemplate
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal  = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={others}
                        namePlaceholder={othersPlaceholder} 
                        amountLabel ={monthyAmountText}
                        monthyAmountPlaceholder ={monthyAmountPlaceholder} 
                        addText = {addText}
                        addRow = {this.addRow}
                        handleName = {this.handleName}
                        handleAmount = {this.handleAmount}
                        removeRow = {this.removeRow} 
                    />
               
            </React.Fragment> 
        );
    }
}
 
export default TaxReturn;