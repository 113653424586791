import React, { Component } from 'react'; 

import CheckboxDropdown from '../../Components/CheckboxDropdown'
import AditionalInputsTemplate from '../../Components/AditionalInputsTemplate'

class Employers extends Component { 

    componentDidMount = () =>{
        this.props.generateEmptyEmployerObj();
    }

    handleCheckboxDropdown = (state, value) =>{this.props.handleCheckboxDropdown(state, value)}  

    addRow = (event, stateObjName) => {this.props.addRow(event, stateObjName)}
    handleName = (event, index,stateObjName) =>{this.props.handleName(event, index, stateObjName)}
    handleAmount = (event, index,stateObjName) =>{ this.props.handleAmount(event, index, stateObjName)}
    removeRow = (index,stateObjName) => {this.props.removeRow(index,stateObjName)}

    render() { 
        const {landData, stepData, stateData} =  this.props.data; 
        
        const {employerNameText, monthyIncomeText, addText, monthyIncomePlaceholder, employerNamePlacehoder} = landData; 
        const {stateElement, stateObjName} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal} = stateData;   
        
        let propsObj = {landData, stepData, stateData}

        return ( 
            <React.Fragment>
                <CheckboxDropdown 
                    data = {propsObj} 
                    handleCheckboxDropdown = {this.handleCheckboxDropdown} />
                 <AditionalInputsTemplate
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElement ={stateElement}
                        stateElementVal  = {stateElementVal}
                        nameLabel={employerNameText}
                        namePlaceholder={employerNamePlacehoder} 
                        amountLabel ={monthyIncomeText}
                        monthyAmountPlaceholder ={monthyIncomePlaceholder} 
                        addText = {addText}
                        addRow = {this.addRow}
                        handleName = {this.handleName}
                        handleAmount = {this.handleAmount}
                        removeRow = {this.removeRow} 
                    />
            </React.Fragment> 
        );
    }
}
 
export default Employers;