import React, { Component } from 'react';

 import Grid from '@material-ui/core/Grid';
 
import {ReactComponent as SouseIcon} from '../../styles/imgs/spouse.svg'; 
import {ReactComponent as ChildrenIcon} from '../../styles/imgs/children.svg'; 
import {ReactComponent as ParentsIcon} from '../../styles/imgs/parents.svg';

import Logo from "../../Components/Logo"
import StepsCounter from "../../Components/StepsCounter"

class FamilyMembers extends Component {
    
 
     handleFamilyMemberChange = (event) => {   
        this.props.handleFamilyMemberChange(event) 
    }

    decreaseValue = (value, name) =>{   
       this.props.decreaseFamilyMember(value, name)
    }

    increaseValue = (value, name) =>{   
        this.props.increaseFamilyMember(value, name)
    }

    render() {   
        const {landData, stepData, stateData} =  this.props.data;

        const {spouseText, childrenText, otherDependentsText} = landData;  
        let {spouse, child , dependants} = stateData.familyMembers 
        

        return (  
            <React.Fragment>  
                <div className=""> 
                    <div className="row border ">
                        <Grid container spacing={0} className='padding-tb-eq sibling-number'>
                            <Grid container item xs={1} alignItems="center">
                                <SouseIcon className={spouse ? 'correctIcon':''}/>   
                            </Grid> 
                            <Grid container item xs={11}>
                                <Grid container item xs={9} alignItems="center">
                                    <p>{spouseText}</p> 
                                </Grid>
                                <Grid container item xs={3} justify="center">
                                    <Grid container item xs={4} justify="center">
                                        <button type='button' onClick={() => this.decreaseValue(spouse, 'spouse')}> - </button>
                                    </Grid>
                                    <Grid container item xs={4}>
                                        <input readOnly type="text" value={spouse} name="spouse" className='counter' onChange={this.handleFamilyMemberChange}/>
                                    </Grid>
                                    <Grid container item xs={4} justify="center">
                                        <button type='button' onClick={() => this.increaseValue(spouse, 'spouse')}> + </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="row border">
                        <Grid container spacing={0} className='padding-tb-eq sibling-number'>
                            <Grid container item xs={1}  alignItems="center">
                                <ChildrenIcon className={child ? 'correctIcon':''}/>   
                            </Grid> 
                            <Grid container item xs={11}>
                                <Grid container item xs={9} alignItems="center">
                                    <p>{childrenText}</p> 
                                </Grid>
                                <Grid container item xs={3} justify="center">
                                    <Grid container item xs={4} justify="center">
                                    <button type='button' onClick={() => this.decreaseValue(child, 'child')}> - </button>
                                    </Grid>
                                    <Grid container item xs={4}>
                                        <input readOnly type="text" value={child} name="child" className='counter' onChange={this.handleFamilyMemberChange}/>
                                    </Grid>
                                    <Grid container item xs={4} justify="center">
                                        <button type='button' onClick={() => this.increaseValue(child,'child')}> + </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="row border">
                        <Grid container spacing={0} className='padding-tb-eq sibling-number'>
                            <Grid container item xs={1}  alignItems="center">
                                <ParentsIcon className={dependants ? 'correctIcon':''}/>  
                            </Grid> 
                            <Grid container item xs={11}>
                                <Grid container item xs={9} alignItems="center">
                                    <p>{otherDependentsText}</p> 
                                </Grid>
                                <Grid container item xs={3} justify="center">
                                    <Grid container item xs={4} justify="center">
                                    <button type='button' onClick={() => this.decreaseValue(dependants, 'dependants')}> - </button>
                                    </Grid>
                                    <Grid container item xs={4}>
                                        <input readOnly type="text" value={dependants} name="dependants" className='counter' onChange={this.handleFamilyMemberChange}/>
                                    </Grid>
                                    <Grid container item xs={4} justify="center">
                                        <button type='button' onClick={() => this.increaseValue(dependants, 'dependants')}> + </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>   
                </div>   
                            
            </React.Fragment> 
        );
    }
}
 
export default FamilyMembers;