import React, { Component } from "react";

import Employers from "./Employers";
import MemberInfo from "./MemberInfo";
import Income from "./Income";
import TaxReturn from "./TaxReturn";
import MilestoneHousehold from "../MilestoneHousehold";

import TopSection from "../../Components/TopSection";
import FooterProgressBarHousehold from "../../Components/FooterProgressBarHousehold";

class EmpoimentWrapper extends Component {
  generateEmptyEmployerObjHouseHold = (round) => {
    this.props.generateEmptyEmployerObjHouseHold(round);
  };

  handleMemberInfo = (event, round) => {
    this.props.handleMemberInfo(event, round);
  };

  handleMemberInfoDate = (event, round) => {
    this.props.handleMemberInfoDate(event, round);
  };

  handleCheckboxDropdownHousehold = (state, value, round) => {
    this.props.handleCheckboxDropdownHousehold(state, value, round);
  };
  handleIncomeChangeHousehold = (event, stateForUpdate, round) => {
    this.props.handleIncomeChangeHousehold(event, stateForUpdate, round);
  };

  handleRowClickHousehold = (inputName, stateForUpdate, val, round) => {
    this.props.handleRowClickHousehold(inputName, stateForUpdate, val, round);
  };

  addRowHousehold = (event, stateObjName, round) => {
    this.props.addRowHousehold(event, stateObjName, round);
  };
  handleNameHousehold = (event, index, stateObjName, round) => {
    this.props.handleNameHousehold(event, index, stateObjName, round);
  };
  handleAmountHousehold = (event, index, stateObjName, round) => {
    this.props.handleAmountHousehold(event, index, stateObjName, round);
  };
  removeRowHousehold = (index, stateObjName, round) => {
    this.props.removeRowHousehold(index, stateObjName, round);
  };

  setRound = (val) => {
    this.props.setRound(val);
  };

  setInitalFamilyMember = () => {
    this.props.setInitalFamilyMember();
  };

  sendToApi = (stateElement, obj, cb) => {
    this.props.sendToApi(stateElement, obj, cb);
  };

  returnView = (propsObj) => {
    const { view } = this.props.stepData;

    if (view == "memberData") {
      return (
        <MemberInfo
          data={propsObj}
          handleMemberInfoDate={this.handleMemberInfoDate}
          handleMemberInfo={this.handleMemberInfo}
          setInitalFamilyMember={this.setInitalFamilyMember}
          months={this.props.months}
          mm={this.props.mm}
          dd={this.props.dd}
          yy={this.props.yy}
        />
      );
    } else if (view == "employed") {
      return (
        <Employers
          data={propsObj}
          addRowHousehold={this.addRowHousehold}
          handleNameHousehold={this.handleNameHousehold}
          handleAmountHousehold={this.handleAmountHousehold}
          removeRowHousehold={this.removeRowHousehold}
          handleCheckboxDropdownHousehold={this.handleCheckboxDropdownHousehold}
          generateEmptyEmployerObjHouseHold={
            this.generateEmptyEmployerObjHouseHold
          }
        />
      );
    } else if (view == "income") {
      return (
        <Income
          data={propsObj}
          handleIncomeChangeHousehold={this.handleIncomeChangeHousehold}
          addRowHousehold={this.addRowHousehold}
          handleNameHousehold={this.handleNameHousehold}
          handleAmountHousehold={this.handleAmountHousehold}
          removeRowHousehold={this.removeRowHousehold}
          handleCheckboxDropdownHousehold={this.handleCheckboxDropdownHousehold}
          handleRowClickHousehold={this.handleRowClickHousehold}
        />
      );
    } else if (view == "expenses") {
      return (
        <TaxReturn
          data={propsObj}
          handleIncomeChangeHousehold={this.handleIncomeChangeHousehold}
          addRowHousehold={this.addRowHousehold}
          handleNameHousehold={this.handleNameHousehold}
          handleAmountHousehold={this.handleAmountHousehold}
          removeRowHousehold={this.removeRowHousehold}
          handleCheckboxDropdownHousehold={this.handleCheckboxDropdownHousehold}
          handleRowClickHousehold={this.handleRowClickHousehold}
        />
      );
    } else if (view == "milestone") {
      return (
        <MilestoneHousehold
          data={propsObj}
          handleChangeSilder={this.handleChangeSilder}
          setRound={this.setRound}
        />
      );
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      altLang,
      logoUrl,
      setLangCode,
      languageCode,
      loading,
    } = this.props;

    let propsObj = {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      altLang,
      logoUrl,
    };
    return (
      <React.Fragment>
        <TopSection
          maxSteps="04"
          houseHold="true"
          data={propsObj}
          setLangCode={setLangCode}
          languageCode={languageCode}
        />
        {this.returnView(propsObj)}

        <FooterProgressBarHousehold
          loading={loading}
          sendToApi={this.sendToApi}
          setRound={this.setRound}
          data={propsObj}
        />
      </React.Fragment>
    );
  }
}

export default EmpoimentWrapper;
