import React from 'react';
import Grid from '@material-ui/core/Grid';

const SiteLink = (props) => { 
    return ( 
        <React.Fragment>
            <Grid container item xs={6} spacing={0} justify="flex-end"> 
                <div className="sitelink">{props.data} </div>
            </Grid>
        </React.Fragment>
    );
}
 
export default SiteLink;  