import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

class StepsCounter extends Component {
    state = {  }
    render() { 
        const {step, maxStep} = this.props;
        return (  
            <React.Fragment>
                <Grid container item xs={6} spacing={0} justify="flex-end" alignItems="center"> 
                    <div className="steps">
                        <span>{step}</span>/{maxStep}
                    </div>
                </Grid>
            </React.Fragment>
        );
    }
}
 
export default StepsCounter;
