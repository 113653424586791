import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import {ReactComponent as CheckBoxYes} from '../styles/imgs/checkbox-yes.svg';
import {ReactComponent as CheckBoxNo} from '../styles/imgs/checkbox-no.svg';
import {ReactComponent as CheckBoxYesSmall} from '../styles/imgs/checkbox-yes-small.svg';
import {ReactComponent as CheckBoxNoSmall} from '../styles/imgs/checkbox-no-small.svg';


class CheckboxDropdown extends Component {

    handleCheckboxDropdown = (state, value) =>{  
        this.props.handleCheckboxDropdown(state, value)
    }

    render() {  
        
        const {landData, stepData, stateData} =  this.props.data 
        const {yesText, noText, yesTextFull, noTextFull} = landData; 
        const {stateElement} = stepData;  
        let { [stateElement]:stateElementVal} = stateData;   
        
        return (
            <React.Fragment> 
                <div className="container">
                    <Grid container className={stateElementVal=='yes' ? 'small-checkbox-wrapper':''}>
                        <Grid direction="column" container item xs={6} alignItems="center" justify="center" 
                        className={"checkbox-container"} 
                        onClick={()=>this.handleCheckboxDropdown({stateElement} ,'yes')}>
                            <CheckBoxYes className={stateElementVal=='yes' ? 'hidden':''}/> 
                            <CheckBoxYesSmall className={'small-icon ' + (stateElementVal!=='yes' ? 'hidden':'')}/> 
                            {
                                stateElementVal=='yes' && noTextFull 
                                    ?<h4 className="checkbox-label">{yesTextFull}</h4>
                                    :<h4 className="checkbox-label">{yesText}</h4>
                            } 
                        </Grid>
                        <Grid direction="column" container item xs={6}  alignItems="center" justify="center" 
                        className={"checkbox-container right " + (stateElementVal=='no' ? 'checked':'')} 
                        onClick={()=>this.handleCheckboxDropdown({stateElement} ,'no')}>
                            <CheckBoxNo className={stateElementVal=='yes' ? 'hidden':''}/>
                            <CheckBoxNoSmall className={'small-icon ' + (stateElementVal!=='yes' ? 'hidden':'')}/> 
                            {
                                stateElementVal!=='yes' && noTextFull 
                                    ?<h4 className="checkbox-label">{noTextFull}</h4>
                                    :<h4 className="checkbox-label">{noText}</h4>
                            }
                        </Grid>
                    </Grid>   
                </div>
            </React.Fragment> 
        );
    }
}
 
export default CheckboxDropdown;