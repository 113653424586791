import React, { Component } from "react";

import Employers from "./Employers";
import Income from "./Income";
import TaxReturn from "./TaxReturn";
import Assets from "./Assets";
import Milestone from "../Milestone";

import TopSection from "../../Components/TopSection";
import FooterProgressBar from "../../Components/FooterProgressBar";

class EmpoimentWrapper extends Component {
  generateEmptyEmployerObj = () => {
    this.props.generateEmptyEmployerObj();
  };

  handleCheckboxDropdown = (state, value) => {
    this.props.handleCheckboxDropdown(state, value);
  };
  handleIncomeChange = (event, stateForUpdate) => {
    this.props.handleIncomeChange(event, stateForUpdate);
  };

  handleRowClick = (inputName, stateForUpdate, val) => {
    this.props.handleRowClick(inputName, stateForUpdate, val);
  };

  addRow = (event, stateObjName) => {
    this.props.addRow(event, stateObjName);
  };
  handleName = (event, index, stateObjName) => {
    this.props.handleName(event, index, stateObjName);
  };
  handleAmount = (event, index, stateObjName) => {
    this.props.handleAmount(event, index, stateObjName);
  };
  removeRow = (index, stateObjName) => {
    this.props.removeRow(index, stateObjName);
  };

  sendToApi = (stateElement, obj, cb) => {
    this.props.sendToApi(stateElement, obj, cb);
  };

  returnView = (propsObj) => {
    const { view } = this.props.stepData;

    if (view == "employed") {
      return (
        <Employers
          data={propsObj}
          addRow={this.addRow}
          handleName={this.handleName}
          handleAmount={this.handleAmount}
          removeRow={this.removeRow}
          handleCheckboxDropdown={this.handleCheckboxDropdown}
          generateEmptyEmployerObj={this.generateEmptyEmployerObj}
        />
      );
    } else if (view == "income") {
      return (
        <Income
          data={propsObj}
          handleIncomeChange={this.handleIncomeChange}
          addRow={this.addRow}
          handleName={this.handleName}
          handleAmount={this.handleAmount}
          removeRow={this.removeRow}
          handleCheckboxDropdown={this.handleCheckboxDropdown}
          handleRowClick={this.handleRowClick}
        />
      );
    } else if (view == "expenses") {
      return (
        <TaxReturn
          data={propsObj}
          handleIncomeChange={this.handleIncomeChange}
          addRow={this.addRow}
          handleName={this.handleName}
          handleAmount={this.handleAmount}
          removeRow={this.removeRow}
          handleCheckboxDropdown={this.handleCheckboxDropdown}
          handleRowClick={this.handleRowClick}
        />
      );
    } else if (view == "milestone") {
      return (
        <Milestone
          data={propsObj}
          handleChangeSilder={this.handleChangeSilder}
        />
      );
    } else if (view == "assets") {
      return (
        <Assets
          data={propsObj}
          handleIncomeChange={this.handleIncomeChange}
          addRow={this.addRow}
          handleName={this.handleName}
          handleAmount={this.handleAmount}
          removeRow={this.removeRow}
          handleCheckboxDropdown={this.handleCheckboxDropdown}
          handleRowClick={this.handleRowClick}
        />
      );
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      personData,
      altLang,
      logoUrl,
      setLangCode,
      languageCode,
      loading,
    } = this.props;

    let propsObj = {
      landData,
      stepData,
      stateData,
      history,
      globalStateData,
      personData,
      altLang,
      logoUrl,
    };
    return (
      <React.Fragment>
        <TopSection
          maxSteps="05"
          data={propsObj}
          financialInfo="true"
          setLangCode={setLangCode}
          languageCode={languageCode}
        />
        {this.returnView(propsObj)}

        <FooterProgressBar
          loading={loading}
          sendToApi={this.sendToApi}
          data={propsObj}
        />
      </React.Fragment>
    );
  }
}

export default EmpoimentWrapper;
