import React, { Component } from 'react';

import { Link } from 'react-router-dom';
 
import { ValidatorForm } from 'react-material-ui-form-validator';

import NumberFormat from 'react-number-format';

import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider'; 




import Logo from "../../Components/Logo"
import StepsCounter from "../../Components/StepsCounter"
 
class HouseHoldTotalIncome extends Component {

    handleChangeSilder = (event, newValue) => {    
        this.props.handleChangeSilder(event, newValue)
    }

    render() {   
        const {landData, stepData, stateData} =  this.props.data; 
        const {aditionalInfo, currencyText, aditionalInfoBottom} = landData; 
        const {stateElement} = stepData; 

        
        let {[stateElement]:stateElemenVal} = stateData;   
        
        return (  
            <React.Fragment>
                    <div className="households-income-wrapper border">
                        <p className="smaill-title">
                            {aditionalInfo}
                        </p>
                        <div className="income-value">
                            <NumberFormat  
                                placeholder="$50,000"
                                value={stateElemenVal}  
                                thousandSeparator={true} 
                                prefix={'$'} 
                                renderText={value => <div>{value}</div>} 
                                onValueChange={(values) => {
                                    const {value} = values; 
                                    this.handleChangeSilder('event',value)
                                }} 
                                isAllowed={(values) => {
                                    const {value} = values;
                                    return value !=='0' && value > -1 && value <= 100000;
                                }}/>
                        </div>
                        <div className="income-value-currency">
                             {currencyText}
                        </div>
                        <div className="row eq slider-wrapper"> 
                        <Grid container> 
                            <Grid container item xs justify="center">
                                <p className="label-value">
                                    $0
                                </p> 
                            </Grid> 
                            <Grid container item xs={9} justify="center">
                                <Slider
                                    value={stateElemenVal} 
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="off"
                                    step={1} 
                                    min={0}
                                    max={100000}
                                    onChange={this.handleChangeSilder}
                                    name={stateElement}
                                    step={100}
                                />
                            </Grid>
                            <Grid container item xs justify="flex-end">
                                <p className="label-value">
                                    $100K +
                                </p> 
                            </Grid>
                        </Grid>
                        <Grid container item xs justify="center">
                                <p className="label-value last">
                                    {aditionalInfoBottom}
                                </p> 
                        </Grid>
                        </div>  
                    </div>   
                            
            </React.Fragment> 
        );
    }
}
 
export default HouseHoldTotalIncome;