import React, { Component, useRef} from 'react';
 
import SignatureCanvas from 'react-signature-canvas'
 
class Signature extends Component { 
    
    state ={
        background:true,
    }

    startSignature = () =>{
        this.setState({background:false})
    }


    sigPad = {}

    saveSignature = () =>{ 
        let signarture = this.sigPad.getTrimmedCanvas().toDataURL('image/png'); 
        this.props.saveSignature(signarture)  
         
    }

    clearignature =() =>{
        this.sigPad.clear()
        this.setState({background:true})
        this.props.clearignature()
    }

    render() { 

        const {landData, stepData, stateData} =  this.props.data; 
        const {clearBtn} = landData;
        
        
        return ( 
            <React.Fragment>
          
                <div className="row">
                    <SignatureCanvas penColor='black'
                    ref={(ref) => { this.sigPad = ref }} 
                    canvasProps={
                        {className:this.state.background ?'sigCanvas bg':'sigCanvas'}
                    }
                    onBegin={this.startSignature}
                    onEnd={this.saveSignature}
                     />
                </div> 
                <div className="row">
                    <p className="clear-btn-signature" onClick={this.clearignature}>{clearBtn}</p>    
                </div>     
            </React.Fragment> 
        );
    }
}
 
export default Signature;