export const hospitalId = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : "advocatia";
// export const hospitalId = "advocatia";

export const logoUrl =
  "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/logos/" +
  hospitalId +
  ".png";
// export const langData =
//   "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/languages/LanguageData.json";

export const langData =
  "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/languages/LanguageData.json";

export const langDataCustom =
  "https://s3.us-east-2.amazonaws.com/selfservice.benefitscreening.com/config/" +
  hospitalId +
  "_config.json";
export const tcLink = "https://www.advocatia.io/terms-conditions";

export const apiUrl =
  "https://" + hospitalId + ".benefitscreening.com/api/selfservice";
  //"https://localhost:44344/api/selfservice";
