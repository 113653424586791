import React, { Component } from "react";
import moment from 'moment';

import { Link } from "react-router-dom";

import Logo from "../Components/Logo";
import { ReactComponent as TapIcon } from "../styles/imgs/Tap_icon.svg";

import { apiUrl } from "../Helpers/urls";

class Welcome extends Component {
  state = {};

  nextStep = () => {
    const { history } = this.props;
    history.push("/information-review");
  };

  loginFromQuickscreen = () => {
    const benefitRecordId = this.props.match.params.id;
    const dob = this.props.match.params.dob;
    const date = moment(dob, "YYYY-MM-DD");
    const data = {
        benefitRecordId: benefitRecordId,
        personData: {
          birthday: {
            month: date.format("MMMM"),
            date: date.format("DD"),
            year: date.format("YYYY"),
          },
        },
    };

    this.props.startLoading();
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": "B9C1A34C-D284-4596-B0B5-478FF3E46E0D",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result == "Error: Access Denied" ||
          result == "Error: Error: Access Denied"
        ) {
          this.props.loginFail();
          this.props.history.push('/');
        } else {
          this.props.loginSuccess();
          if (this.props.match.params.id) {
            sessionStorage.setItem(
              "paramsId",
              JSON.stringify(this.props.match.params.id)
            );
          }
          this.props.setToState(result);
          this.props.setLangCode(result.languageCode);
        }
      });
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if(this.props.match.params.id && this.props.match.params.dob) { this.loginFromQuickscreen(); }
  };

  render() {
    const { firstName } = this.props.data.personData;
    const { altLang, logoUrl, setLangCode, languageCode } = this.props;
    const { helloText, intoText } = this.props.landData;

    return (
      <React.Fragment>
        <div className="bg-blue">
          <header>
            <div className="row">
              <Logo
                setLangCode={setLangCode}
                languageCode={languageCode}
                altLang={altLang}
                logoUrl={logoUrl}
              />
            </div>
          </header>
          <div onClick={this.nextStep}>
            <div className="row">
              <h2 className="section-title full-width">
                {helloText}
                <br />
                {firstName}
              </h2>
            </div>

            <div className="main-icon">
              <TapIcon />
            </div>
            <h6 className="sub-title text-center">{intoText}</h6>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Welcome;
