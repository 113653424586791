import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as CheckBoxYes } from "./../styles/imgs/checkbox-yes.svg";
import { ReactComponent as CheckBoxNo } from "./../styles/imgs/checkbox-no.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import Logo from "./../Components/Logo";

class Logout extends Component {
  state = {};

  componentDidMount() {
    this.props.sendToApi();
  }

  backToPrevPage = () => {
    this.props.history.goBack();
  };
  endSession = () => {
    if (sessionStorage.getItem("paramsId")) {
      this.props.history.push(
        "/screen/" + JSON.parse(sessionStorage.getItem("paramsId"))
      );
    } else {
      this.props.history.push("/");
    }
    this.props.endSession();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { mainTitle, yesText, noText } = this.props.landData;
    const { altLang, logoUrl, setLangCode, languageCode, loading } = this.props;
    return (
      <React.Fragment>
        <div className="top-section bottom-space top-section-logout">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">{mainTitle}</h2>
          </div>
        </div>
        <div className="container container-logout">
          <Grid container>
            <Grid
              direction="column"
              container
              item
              xs
              alignItems="center"
              justify="center"
              className="checkbox-container border-right"
              onClick={this.endSession}
            >
              <CheckBoxYes />
              <h4 className="checkbox-label">{yesText}</h4>
            </Grid>
            <Grid
              direction="column"
              container
              item
              xs
              alignItems="center"
              justify="center"
              className="checkbox-container"
              onClick={this.backToPrevPage}
            >
              <CheckBoxNo />
              <h4 className="checkbox-label">{noText}</h4>
            </Grid>
          </Grid>
        </div>
        <footer className="logout-footer">{loading && <Loader />}</footer>
      </React.Fragment>
    );
  }
}

export default Logout;
