import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import LinearProgress from "@material-ui/core/LinearProgress";

class FooterProgressBar extends Component {
  checkValidationEmp = (obj) => {
    for (var key in obj) {
      for (var v in obj[key]) {
        if (obj[key].name == "" || obj[key].value == "") {
          return false;
        }
        // if (obj[key][v] == '' || obj[key][v] == null){
        //     return false;
        // }
      }
    }
    return true;
  };

  checkValidationCombined = (obj) => {
    const { stateData, stepData } = this.props.data;
    const { stateList } = stepData;
    let { [stateList]: stateL } = stateData;

    if (obj.length > 0) {
      let currentObj = obj[obj.length - 1];
      if (
        this.checkValidationList(stateL) &&
        currentObj.name == "" &&
        currentObj.value == ""
      ) {
        // return true;
        return false;
      } else if (
        this.checkValidationList(stateL) &&
        !currentObj.name == "" &&
        !currentObj.value == ""
      ) {
        return true;
      } else if (!currentObj.name == "" && !currentObj.value == "") {
        return true;
      }
    } else {
      if (this.checkValidationList(stateL)) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkValidationList = (obj) => {
    for (var key in obj) {
      for (var v in obj[key]) {
        if (obj[key][v] !== "" || obj[key][v] !== null) {
          return true;
        }
      }
    }
    return false;
  };

  goToPrevStep = () => {
    const { history, stepData, stateData, personData } = this.props.data;

    const { prevLink, view, milestone } = stepData;
    if (view == "milestone" && milestone == "2") {
      if (new Date().getFullYear() - personData.birthday.year <= 12) {
        this.props.history.push("/general-info/step8");
      } else {
        this.props.history.push(prevLink);
      }
    } else if (stepData.stateElement == "blind" && stateData.gender == "Male") {
      this.props.history.push("/general-info/step3");
    } else if (
      stepData.stateElement == "blind" &&
      personData.gender == "Male"
    ) {
      this.props.history.push("/general-info/step2");
    } else if (
      stepData.stateElement == "blind" &&
      personData.gender == "Female"
    ) {
      this.props.history.push("/general-info/step4");
    } else if (
      stepData.stateElement == "pregnant" &&
      (personData.gender == "Male" || personData.gender == "Female")
    ) {
      this.props.history.push("/general-info/step3");
    } else {
      this.props.history.push(prevLink);
    }
  };

  sendToApi = (url) => {
    const { stateElement } = this.props.data.stepData;
    if (!this.props.loading) {
      this.props.sendToApi(stateElement, null, () => {
        this.props.history.push(url);
      });
    }
  };

  goToNextStep = () => {
    const {
      history,
      stepData,
      stateData,
      globalStateData,
      personData,
    } = this.props.data;
    const {
      nextLink,
      checkFormembers,
      view,
      milestone,
      stateElement,
      stateList,
    } = stepData;
    if (checkFormembers) {
      const {
        child,
        dependants,
        spouse,
      } = globalStateData.generalInfo.familyMembers;
      if (child || dependants || spouse) {
        this.sendToApi(nextLink);
      } else {
        this.sendToApi("/documents/step1");
      }
    } else if (milestone == "1" && view == "milestone") {
      if (new Date().getFullYear() - personData.birthday.year <= 12) {
        this.sendToApi("/financial-info/step5");
      } else {
        this.sendToApi(nextLink);
      }
    } else if (
      stepData.stateElement == "usCitizen" &&
      personData.gender == "Male"
    ) {
      this.sendToApi("/general-info/step3");
    } else if (
      stepData.stateElement == "usCitizen" &&
      personData.gender == "Female"
    ) {
      this.sendToApi("/general-info/step4");
    } else if (
      stepData.stateElement == "gender" &&
      (personData.gender == "Male" || stateData.gender == "Male")
    ) {
      this.sendToApi("/general-info/step5");
    } else {
      this.sendToApi(nextLink);
    }
  };

  render() {
    const { loading } = this.props;
    const { landData, stepData, stateData } = this.props.data;

    const { nextBtn, prevBtn, endSession } = landData;
    const {
      prevLink,
      completed,
      stateElement,
      stateObjName,
      stateList,
    } = stepData;

    let { [stateElement]: stateElementVal } = stateData;
    let { [stateObjName]: stateObjVal } = stateData;
    let { [stateList]: stateL } = stateData;

    return (
      <footer className={loading ? "loading" : ""}>
        <LinearProgress variant="determinate" value={completed} />
        <div className="row">
          <Grid container>
            <Grid container item xs justify="flex-start">
              <div
                onClick={() => {
                  if (!loading) {
                    this.goToPrevStep();
                  }
                }}
                className="prev-btn"
              >
                <div className="footer-loader-wrapper left">
                  <ArrowBackIosIcon />
                  <span>{prevBtn}</span>
                </div>
              </div>
            </Grid>
            <Grid container item xs justify="center">
              <Link
                to="/logout"
                className="end-sesion-btn"
                onClick={(e) => {
                  if (loading) {
                    e.preventDefault();
                  }
                }}
              >
                {" "}
                {endSession}{" "}
              </Link>
            </Grid>
            <Grid container item xs justify="flex-end">
              <span className="hidden">
                {stateElement == "employed"
                  ? stateElementVal == "yes"
                    ? (stateElementVal = this.checkValidationEmp(stateObjVal))
                    : ""
                  : ""}
                {stateElement == "gender"
                  ? stateElementVal
                    ? (stateElementVal = true)
                    : ""
                  : ""}

                {stateElement == "income" ||
                stateElement == "assets" ||
                stateElement == "expenses"
                  ? stateElementVal == "yes"
                    ? (stateElementVal = this.checkValidationCombined(
                        stateObjVal
                      ))
                    : ""
                  : ""}

                {stateElement == "milestone" ||
                stateElement == "houseHoldTotalIncome"
                  ? (stateElementVal = "true")
                  : ""}
              </span>
              {stateElementVal ? (
                <div
                  onClick={() => {
                    if (!loading) {
                      this.goToNextStep();
                    }
                  }}
                  className="next-btn"
                >
                  <div className="footer-loader-wrapper">
                    <span>{nextBtn}</span>

                    {loading ? <Loader /> : <ArrowForwardIosIcon />}
                  </div>
                </div>
              ) : (
                <div
                  className="disabled next-btn"
                  onClick={(event) => event.preventDefault()}
                >
                  <div className="footer-loader-wrapper">
                    <span>{nextBtn}</span>

                    {loading ? <Loader /> : <ArrowForwardIosIcon />}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </footer>
    );
  }
}

export default withRouter(FooterProgressBar);
