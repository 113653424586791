import React, { Component } from "react";

import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Logo from "../Components/Logo";
import SiteLink from "../Components/SiteLink";
import { ReactComponent as NotFoundIcon } from "../styles/imgs/notFound.svg";

class NotFound extends Component {
  state = {};

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  
  render() {
    const {
      siteName,
      mainTitle,
      aditionalInfo,
      footertext,
    } = this.props.landData;
    const { altLang, logoUrl, setLangCode, languageCode } = this.props;
    return (
      <React.Fragment>
        <Link to="/">
          <div className="bg-blue">
            <header>
              <div className="row">
                <Grid container spacing={0} alignItems="center">
                  <Logo
                    setLangCode={setLangCode}
                    languageCode={languageCode}
                    altLang={altLang}
                    logoUrl={logoUrl}
                  />
                  <SiteLink data={siteName} />
                </Grid>
              </div>
            </header>
            <div className="row">
              <div className="not-found-icon">
                <NotFoundIcon />
                <h3 className="mainText">{mainTitle}</h3>
                <h3 className="mainText">{aditionalInfo}</h3>
              </div>
              <p className="footertext">{footertext}</p>
            </div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
}

export default NotFound;
