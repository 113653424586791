import React, { Component } from "react";
import Rating from "@material-ui/lab/Rating";

import Grid from "@material-ui/core/Grid";

import { Link, withRouter } from "react-router-dom";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import Logo from "./../Components/Logo";
import SiteLink from "./../Components/SiteLink";

class FeedBack extends Component {
  handleRaiting = (event, value) => {
    this.props.handleRaiting(event, value);
  };
  handleFeedback = (event) => {
    this.props.handleFeedback(event);
  };

  sendToApi = () => {
    this.props.sendToApi(null, null, () =>
      this.props.history.push("/thankyou")
    );
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      landData,
      stepData,
      stateData,
      setLangCode,
      languageCode,
      loading,
    } = this.props;
    const {
      siteName,
      mainTitle,
      labelText,
      placeholderText,
      nextBtn,
    } = landData;
    let { nextLink } = stepData;
    const { rating, feedbackText } = stateData;
    const { altLang, logoUrl } = this.props;

    return (
      <React.Fragment>
        <div className="top-section bottom-space">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
                <SiteLink data={siteName} className="grey" />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">{mainTitle}</h2>
          </div>
        </div>
        <div className="feedback-page border row eq">
          <div className="raiting-wrapper">
            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => {
                this.handleRaiting(event, newValue);
              }}
            />
          </div>

          <div className="feedback-wrapper border-tb padding-tb-eq">
            <div className="row">
              <label>{labelText}</label>
              <textarea
                type="text"
                placeholder={placeholderText}
                name="feedbackText"
                value={feedbackText}
                onChange={this.handleFeedback}
              />
            </div>
          </div>
        </div>
        <footer className={loading ? "loading" : ""}>
          <div className="row">
            <Link
              to={nextLink}
              onClick={(e) => {
                e.preventDefault();
                this.sendToApi();
              }}
              className="next-btn center"
            >
              <div className="footer-loader-wrapper">
                <span>{nextBtn}</span>
                {loading ? <Loader /> : <ArrowForwardIosIcon />}
              </div>
            </Link>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default withRouter(FeedBack);
