import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { ReactComponent as FirstNameIcon } from "../../styles/imgs/firstnameIcon.svg";
import { ReactComponent as LastNameIcon } from "../../styles/imgs/lastnameIcon.svg";
import { ReactComponent as GenderIcon } from "../../styles/imgs/genderIcon.svg";
import { ReactComponent as SsnIcon } from "../../styles/imgs/ssnIcon.svg";
import { ReactComponent as SouseIcon } from "../../styles/imgs/spouse.svg";
import { ReactComponent as DateIcon } from "../../styles/imgs/birthdayIcon.svg";

import MonthsArray from "../../json/Months";
import DatesArray from "../../json/Dates";
import YearsArray from "../../json/Years";

import Logo from "../../Components/Logo";
import StepsCounter from "../../Components/StepsCounter";

const monthsMap = {
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  junе: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December",
};

class MemberInfo extends Component {
  componentDidMount() {
    // this.props.setInitalFamilyMember()
  }

  handleMemberInfo = (event) => {
    const { round } = this.props.data.stepData;
    this.props.handleMemberInfo(event, round);
  };

  handleMemberInfoDate = (event) => {
    const { round } = this.props.data.stepData;
    this.props.handleMemberInfoDate(event, round);
  };

  render() {
    const { months } = this.props;
    const { landData, stepData, stateData, globalStateData } = this.props.data;
    const {
      memberOptions,
      firstNameLabel,
      relationshipLabel,
      lastNameLabel,
      genderLabel,
      ssnLabel,
      phoneLabel,
      emailLabel,
      birthdateLabel,
      addressLabel,
      inputPlaceholder,
      maleText,
      femaleText,
    } = landData;
    const {
      select,
      spouse,
      parent,
      child,
      sibling,
      stepparent,
      stepchild,
      grandparent,
      grandchild,
      aunt,
      niece,
      cousin,
    } = memberOptions;
    const { round } = stepData;
    // const {memberCounter, generalInfo } = globalStateData
    const { generalInfo } = globalStateData;

    let {
      firstName,
      lastName,
      gender,
      socialSecurityNumber,
      relationship,
      birthday,
    } = stateData[round].personData;
    const { date, month, year } = birthday;

    if (relationship == "spouse") {
      relationship = "Spouse";
    }
    if (relationship == "child") {
      relationship = "Child";
    }

    return (
      <React.Fragment>
        <div className="container">
          <Grid container className="border focus-input">
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="padding-l"
            >
              <Grid item xs={2}>
                <SouseIcon
                  className={
                    "small-icon " + (relationship ? "correctIcon" : "")
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <FormControl className="full-width">
                  <InputLabel shrink className="date-picker-label">
                    {relationshipLabel}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field padding-tb no-lp"
                    value={relationship}
                    onChange={this.handleMemberInfo}
                    displayEmpty
                    inputProps={{
                      name: "relationship",
                    }}
                  >
                    <MenuItem disabled value="">
                      {select}
                    </MenuItem>
                    <MenuItem value="Spouse">{spouse}</MenuItem>
                    {/* <MenuItem disabled={memberCounter.spouse==generalInfo.familyMembers.spouse} value="Spouse">{spouse}</MenuItem>   */}
                    {/* <MenuItem disabled={memberCounter.child==generalInfo.familyMembers.child} value="Child">{child}</MenuItem>   */}
                    <MenuItem value="Child">{child}</MenuItem>
                    <MenuItem value="Parent">{parent}</MenuItem>
                    <MenuItem value="Sibling">{sibling}</MenuItem>
                    <MenuItem value="Stepparent">{stepparent}</MenuItem>
                    <MenuItem value="Stepchild">{stepchild}</MenuItem>
                    <MenuItem value="Grandparent">{grandparent}</MenuItem>
                    <MenuItem value="Grandchild">{grandchild}</MenuItem>
                    <MenuItem value="Aunt/Uncle">{aunt}</MenuItem>
                    <MenuItem value="Niece/Nephew">{niece}</MenuItem>
                    <MenuItem value="Cousin">{cousin}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l focus-input"
            >
              <Grid item xs={2}>
                <FirstNameIcon className={firstName ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={firstNameLabel}
                  onChange={this.handleMemberInfo}
                  name="firstName"
                  className={"full-width padding-tb"}
                  type="text"
                  value={firstName}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <LastNameIcon className={lastName ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={lastNameLabel}
                  onChange={this.handleMemberInfo}
                  name="lastName"
                  className={"full-width padding-tb"}
                  type="text"
                  value={lastName}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l focus-input"
            >
              <Grid item xs>
                <GenderIcon className={gender ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <FormControl className="full-width">
                  <InputLabel shrink className="date-picker-label">
                    {genderLabel}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field padding-tb no-lp"
                    value={gender}
                    onChange={this.handleMemberInfo}
                    displayEmpty
                    inputProps={{
                      name: "gender",
                    }}
                  >
                    <MenuItem disabled value="">
                      {inputPlaceholder}
                    </MenuItem>
                    <MenuItem key={1} value={"Male"}>
                      {maleText}
                    </MenuItem>
                    <MenuItem key={2} value={"Female"}>
                      {femaleText}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <SsnIcon
                  className={socialSecurityNumber ? "correctIcon" : ""}
                />
              </Grid>
              <Grid item xs={10}>
                <InputMask
                  mask="999-99-9999"
                  value={socialSecurityNumber}
                  onChange={this.handleMemberInfo}
                >
                  {() => (
                    <TextField
                      label={ssnLabel}
                      onChange={this.handleMemberInfo}
                      name="socialSecurityNumber"
                      className={"full-width padding-tb"}
                      type="text"
                      value={socialSecurityNumber}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Grid>
          <div className="row border-tb birthdate-verification">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs>
                <DateIcon
                  className={
                    birthday.month &&
                    birthday.date &&
                    birthday.year &&
                    "correctIcon"
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <FormControl>
                  <InputLabel shrink className="date-picker-label">
                    {birthdateLabel}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field padding-tb"
                    value={month}
                    onChange={this.handleMemberInfoDate}
                    displayEmpty
                    inputProps={{
                      name: "month",
                    }}
                  >
                    <MenuItem disabled value="">
                      {this.props.mm || "Month"}
                    </MenuItem>
                    {MonthsArray.map((month) => {
                      return (
                        <MenuItem key={month} value={monthsMap[month]}>
                          {months
                            ? months[month] || months.june
                            : monthsMap[month]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field small padding-tb"
                    value={date}
                    onChange={this.handleMemberInfoDate}
                    displayEmpty
                    inputProps={{
                      name: "date",
                    }}
                  >
                    <MenuItem disabled value="">
                      {this.props.dd || "Date"}
                    </MenuItem>
                    {DatesArray.map((date) => {
                      return (
                        <MenuItem key={date} value={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field small padding-tb"
                    value={year}
                    onChange={this.handleMemberInfoDate}
                    displayEmpty
                    inputProps={{
                      name: "year",
                    }}
                  >
                    <MenuItem disabled value="">
                      {this.props.yy || "Year"}
                    </MenuItem>
                    {YearsArray.map((year) => {
                      return (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MemberInfo;
