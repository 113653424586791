import React, { Component } from "react";

import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { ReactComponent as FirstNameIcon } from "../styles/imgs/firstnameIcon.svg";
import { ReactComponent as LastNameIcon } from "../styles/imgs/lastnameIcon.svg";
import { ReactComponent as GenderIcon } from "../styles/imgs/genderIcon.svg";
import { ReactComponent as SsnIcon } from "../styles/imgs/ssnIcon.svg";
import { ReactComponent as PhoneIcon } from "../styles/imgs/phoneIcon.svg";
import { ReactComponent as EmailIcon } from "../styles/imgs/emaiIcon.svg";
import { ReactComponent as AddressIcon } from "../styles/imgs/addressIcon.svg";
import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as DateIcon } from "../styles/imgs/birthdayIcon.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import MonthsArray from "../json/Months";
import DatesArray from "../json/Dates";
import YearsArray from "../json/Years";
import StatesArray from "../json/States";

import Logo from "../Components/Logo";
import CancelBtn from "../Components/CancelBtn";

const monthsMap = {
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  june: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December",
};

class InformationReview extends Component {
  handleChange = (event) => {
    this.props.handleChange(event);
  };
  handleChangeGender = (event) => {
    this.props.handleChangeGender(event);
  };
  handleChangeDate = (event) => {
    this.props.handleChangeDate(event);
  };
  handleChangeAddress = (event) => {
    this.props.handleChangeAddress(event);
  };

  sendToApi = () => {
    if (!this.props.loading) {
      this.props.sendToApi(null, null, () => {
        this.props.history.push("/signature");
      });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      mainTitle,
      intoText,
      formTitle,
      firstNameLabel,
      lastNameLabel,
      genderLabel,
      ssnLabel,
      phoneLabel,
      emailLabel,
      birthdateLabel,
      nextBtn,
      prevBtn,
      endSession,
      addressLabelLine1,
      addressLabelLine2,
      cityLabel,
      stateLabel,
      zipLabel,
      inputPlaceholder,
      maleText,
      femaleText,
    } = this.props.landData;
    const {
      firstName,
      lastName,
      gender,
      socialSecurityNumber,
      phone,
      email,
      address,
      birthday,
    } = this.props.data.personData;
    const { date, month, year } = birthday;
    const { line1, line2, city, state, zipCode } = address;
    const {
      altLang,
      logoUrl,
      setLangCode,
      languageCode,
      loading,
      mm,
      dd,
      yy,
      months,
    } = this.props;

    return (
      <React.Fragment>
        <div className="top-section bottom-space">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">{mainTitle}</h2>
            <p className="text-intro">{intoText}</p>
          </div>
        </div>

        <div className="row">
          <h3 className="form-title">{formTitle}</h3>
        </div>
        <div className="container">
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l focus-input"
            >
              <Grid item xs>
                <FirstNameIcon className={firstName ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={firstNameLabel}
                  onChange={this.handleChange}
                  name="firstName"
                  className={"full-width padding-tb"}
                  type="text"
                  value={firstName}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <LastNameIcon className={lastName ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={lastNameLabel}
                  onChange={this.handleChange}
                  name="lastName"
                  className={"full-width padding-tb"}
                  type="text"
                  value={lastName}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l"
            >
              <Grid item xs>
                <GenderIcon className={gender ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <FormControl className="full-width">
                  <InputLabel shrink className="date-picker-label">
                    {genderLabel}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field padding-tb no-lp"
                    value={gender}
                    onChange={this.handleChangeGender}
                    displayEmpty
                    inputProps={{
                      name: "gender",
                    }}
                  >
                    <MenuItem disabled value="">
                      {inputPlaceholder}
                    </MenuItem>
                    <MenuItem key={1} value={"Male"}>
                      {maleText}
                    </MenuItem>
                    <MenuItem key={2} value={"Female"}>
                      {femaleText}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <SsnIcon
                  className={socialSecurityNumber ? "correctIcon" : ""}
                />
              </Grid>
              <Grid item xs={10}>
                <InputMask
                  mask="999-99-9999"
                  value={socialSecurityNumber}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      label={ssnLabel}
                      onChange={this.handleChange}
                      name="socialSecurityNumber"
                      className={"full-width padding-tb"}
                      type="text"
                      value={socialSecurityNumber}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l focus-input"
            >
              <Grid item xs>
                <PhoneIcon className={phone ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <InputMask
                  mask="(999) 999-9999"
                  value={phone}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      label={phoneLabel}
                      onChange={this.handleChange}
                      name="phone"
                      className={"full-width padding-tb"}
                      type="text"
                      value={phone}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <EmailIcon className={email ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={emailLabel}
                  onChange={this.handleChange}
                  name="email"
                  className={"full-width padding-tb"}
                  type="text"
                  value={email}
                />
              </Grid>
            </Grid>
          </Grid>

          <div className="row border-tb birthdate-verification">
            <Grid container spacing={0} alignItems="center">
              <Grid item xs>
                <DateIcon
                  className={
                    birthday.month &&
                    birthday.date &&
                    birthday.year &&
                    "correctIcon"
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <FormControl>
                  <InputLabel shrink className="date-picker-label">
                    {birthdateLabel}
                  </InputLabel>
                  <Select
                    disabled
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field padding-tb"
                    value={month}
                    onChange={this.handleChangeDate}
                    displayEmpty
                    inputProps={{
                      name: "month",
                    }}
                  >
                    <MenuItem disabled value="">
                      {this.props.mm || "Month"}
                    </MenuItem>
                    {MonthsArray.map((month) => {
                      return (
                        <MenuItem key={month} value={monthsMap[month]}>
                          {months ? months[month] : monthsMap[month]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    disabled
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field small padding-tb"
                    value={date}
                    onChange={this.handleChangeDate}
                    displayEmpty
                    inputProps={{
                      name: "date",
                    }}
                  >
                    <MenuItem disabled value="">
                      Date
                    </MenuItem>
                    {DatesArray.map((date) => {
                      return (
                        <MenuItem key={date} value={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    disabled
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field small padding-tb"
                    value={year}
                    onChange={this.handleChangeDate}
                    displayEmpty
                    inputProps={{
                      name: "year",
                    }}
                  >
                    <MenuItem disabled value="">
                      Year
                    </MenuItem>
                    {YearsArray.map((year) => {
                      return (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={6}
              className="border padding-l focus-input"
            >
              <Grid item xs>
                <AddressIcon className={line1 ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={addressLabelLine1}
                  onChange={this.handleChangeAddress}
                  name="line1"
                  className={"full-width padding-tb address-input"}
                  type="text"
                  value={line1}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={6}
            >
              <Grid item xs>
                <AddressIcon className={line2 ? "correctIcon" : ""} />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={addressLabelLine2}
                  onChange={this.handleChangeAddress}
                  name="line2"
                  className={"full-width padding-tb address-input"}
                  type="text"
                  value={line2}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              alignItems="center"
              sm={5}
              className="border padding-l focus-input"
            >
              <Grid item sm={3} xs={2}>
                <AddressIcon className={city ? "correctIcon" : ""} />
              </Grid>
              <Grid item sm={7} xs={10}>
                <TextField
                  label={cityLabel}
                  onChange={this.handleChangeAddress}
                  name="city"
                  className={"full-width padding-tb address-input"}
                  type="text"
                  value={city}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={3}
            >
              <Grid item sm={3} xs={2}>
                <AddressIcon className={state ? "correctIcon" : ""} />
              </Grid>
              <Grid item sm={7} xs={10}>
                <FormControl>
                  <InputLabel shrink className="date-picker-label">
                    {stateLabel}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      getContentAnchorEl: null,
                    }}
                    className="select-field state-select padding-tb no-lp"
                    value={state}
                    onChange={this.handleChangeAddress}
                    displayEmpty
                    inputProps={{
                      name: "state",
                    }}
                  >
                    <MenuItem disabled value="">
                      State
                    </MenuItem>
                    {StatesArray.map((state) => {
                      return (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="border-left border focus-input"
              alignItems="center"
              sm={4}
            >
              <Grid item sm={3} xs={2}>
                <AddressIcon className={zipCode ? "correctIcon" : ""} />
              </Grid>
              <Grid item sm={7} xs={10}>
                <TextField
                  label={zipLabel}
                  onChange={this.handleChangeAddress}
                  name="zipCode"
                  className={"full-width padding-tb address-input"}
                  type="text"
                  value={zipCode}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <footer className={loading ? "loading" : ""}>
          <div className="row">
            <Grid container>
              <Grid container item xs justify="flex-start">
                {/* <Link to='/birthdate-verification' className="prev-btn"><ArrowBackIosIcon/><span>{prevBtn}</span></Link> */}
              </Grid>
              <Grid container item xs justify="center">
                <Link
                  to="/logout"
                  className="end-sesion-btn"
                  onClick={(e) => {
                    if (loading) {
                      e.preventDefault();
                    }
                  }}
                >
                  {" "}
                  {endSession}{" "}
                </Link>
              </Grid>
              <Grid container item xs justify="flex-end">
                {firstName &&
                lastName &&
                phone.indexOf("_") === -1 &&
                socialSecurityNumber.indexOf("_") === -1 &&
                state ? (
                  <Link
                    to="/signature"
                    className="next-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.sendToApi();
                    }}
                  >
                    <div className="footer-loader-wrapper">
                      <span>{nextBtn}</span>

                      {loading ? <Loader /> : <ArrowForwardIosIcon />}
                    </div>
                  </Link>
                ) : (
                  <Link
                    to="/signature"
                    onClick={(event) => event.preventDefault()}
                    className="disabled next-btn"
                  >
                    <span>{nextBtn}</span>
                    <ArrowForwardIosIcon />
                  </Link>
                )}
              </Grid>
            </Grid>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default InformationReview;
