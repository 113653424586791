import React, { Component } from "react";

import { Link } from "react-router-dom";

import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import InputMask from "react-input-mask";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as LoginIcon } from "../styles/imgs/login-icon.svg";
import { ReactComponent as FirstNameIcon } from "../styles/imgs/firstnameIcon.svg";
import { ReactComponent as LastNameIcon } from "../styles/imgs/lastnameIcon.svg";
import { ReactComponent as PhoneIcon } from "../styles/imgs/phoneIcon.svg";
import { ReactComponent as EmailIcon } from "../styles/imgs/emaiIcon.svg";
import { ReactComponent as DateIcon } from "../styles/imgs/birthdayIcon.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import Logo from "../Components/Logo";
import PersonalData from "../json/PersonalData";

import MonthsArray from "../json/Months";
import DatesArray from "../json/Dates";
import YearsArray from "../json/Years";

import { apiUrl, tcLink } from "../Helpers/urls";
import { encode, decode } from "he";

const monthsMap = {
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  june: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December",
};

class LoginCode extends Component {
  state = {
    patientCode: "",
    firstName: "",
    lastName: "",
    birthday: {
      month: "",
      date: "",
      year: "",
    },
    email: "",
    phone: "",
    agreeTc: false,
    correctPatientCode: false,
    wrongPatientCode: false,
    withoutCode: true,
    loader: false,
  };

  dailDigit = (digit) => {
    const { patientCode, correctPatientCode, wrongPatientCode } = this.state;
    let digits = patientCode;

    if (digits.length > 4) {
      return;
    }

    if (digits.length < 6) {
      digits = digits.concat(digit);
      this.setState({ patientCode: digits });
      this.props.setAccessCode(digits);
    }

    if (digits.length > 4) {
      // this.setState({
      //   loader: true,
      // });
      // let data = {
      //   accessCode: digits.toString(),
      // };
      // const url = apiUrl;
      // fetch(url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //     // 'Origin': 'http://localhost:3000/',
      //     "x-api-key": "B9C1A34C-D284-4596-B0B5-478FF3E46E0D",
      //   },
      //   body: JSON.stringify(data),
      // })
      //   .then((response) => response.json())
      //   .then((result) => {
      //     this.setState({
      //       loader: false,
      //     });
      //     if (result == "Error: Access Denied") {
      //       this.setState({
      //         correctPatientCode: false,
      //         wrongPatientCode: true,
      //       });
      //     } else {
      //       this.props.getUserRawData(result);
      //       this.setState({
      //         correctPatientCode: true,
      //         wrongPatientCode: false,
      //       });
      //       // this.props.setPersonalData(PersonalData)
      //     }
      //   });
      this.props.setAccessCode(digits);
      this.setState({
        correctPatientCode: true,
        wrongPatientCode: false,
      });
    }
  };

  deleteDigits = () => {
    const { patientCode } = this.state;

    let digits = patientCode;
    digits = digits.slice(0, -1);

    this.setState({
      patientCode: digits,
      correctPatientCode: false,
      wrongPatientCode: false,
    });

    // this.props.endSession({});
  };

  withoutCode = () => {
    this.setState({ withoutCode: true });
  };

  withCode = () => {
    this.setState({ withoutCode: false });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeDOB = (event) => {
    this.setState({
      ...this.state,
      birthday: {
        ...this.state.birthday,
        [event.target.name]: event.target.value.toString(),
      },
    });
  };
  submitForm = () => {
    this.setState({ loader: true });
    const dataForApi = {
      benefitRecordId: "00000000-0000-0000-0000-000000000000",
      personData: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        birthday: this.state.birthday,
        email: this.state.email,
        phone: this.state.phone,
      },
      lastCompletedStep: window.location.pathname,
      outreachRequestId: this.props.outreachRequestId,
      src: this.props.src
    };

    const url = apiUrl;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": "B9C1A34C-D284-4596-B0B5-478FF3E46E0D",
      },
      body: JSON.stringify({
        ...dataForApi,
        accessCode: this.state.toString(),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result == "Error: Access Denied" ||
          result == "Error: Error: Access Denied"
        ) {
          this.setState({ loader: false });
        } 
        else {
          const { history } = this.props;
          sessionStorage.setItem("skipDOB", JSON.stringify(true));
          this.props.setLangCode(result.languageCode);
          this.props.setToState(result);
          this.setState({ loader: false });
          history.push("/welcome");
        }
      });
  };

  handleTc = (event) => {
    this.setState({
      agreeTc: event.target.checked,
    });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({
      codePlaceholder: this.props.landData.codePlaceholder,
    });
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromBirthdateVerification
    ) {
      this.withCode();
    }
  };

  render() {
    const {
      withoutCode,
      patientCode,
      correctPatientCode,
      wrongPatientCode,
      firstName,
      lastName,
      agreeTc,
      phone,
      email,
      loader,
    } = this.state;
    const { month, date, year } = this.state.birthday;
    const {
      codePlaceholder,
      companyName,
      deleteText,
      intoText,
      mainTitle,
      continueText,
      doNotHaveCode,
      haveCode,
      or,
      agreeTo,
      termsConditions,
    } = this.props.landData;
    const {
      firstNameLabel,
      lastNameLabel,
      phoneLabel,
      emailLabel,
      birthdateLabel,
    } = this.props.landData2;
    const {
      logoUrl,
      altLang,
      setLangCode,
      languageCode,
      mm,
      dd,
      yy,
      months,
      formTitle,
      formTitleCode,
    } = this.props;
    const { Color } = altLang;
    return (
      <React.Fragment>
        <div className="top-section">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">
              {mainTitle} <br />
              {companyName}
            </h2>
            <p className="text-intro">
              {this.props.currLang === "es"
                ? "Benefit Triage puede evaluar rápidamente su elegibilidad y ayudarle a registrarse para una cobertura más rápido."
                : intoText}
            </p>
          </div>
          <div className="row">
            <h3 className="form-title">
              {!withoutCode ? formTitleCode : formTitle}
            </h3>
          </div>
        </div>

        <form ref="form" onSubmit={this.submitForm}>
          {!withoutCode && (
            <React.Fragment>
              <div
                className={
                  "row border-tb " +
                  (correctPatientCode ? "correct-code " : "") +
                  (wrongPatientCode ? "wrong-code " : "")
                }
              >
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      justify="center"
                      autoComplete="new-password"
                      placeholder={codePlaceholder}
                      name="patientCode"
                      className={
                        "full-width padding-tb-eq code-input " +
                        (patientCode.length && "full ")
                      }
                      type="text"
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     {loader ? <Loader /> : <LoginIcon />}
                        //   </InputAdornment>
                        // ),
                        readOnly: true,
                      }}
                      value={patientCode}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="row no-padding">
                <Grid container spacing={0} className="dailpad-wrapper">
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(1)}
                  >
                    <div>
                      <div className="digit">1</div>
                      <div className="letters"> </div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-full"
                    onClick={() => this.dailDigit(2)}
                  >
                    <div>
                      <div className="digit">2</div>
                      <div className="letters">ABC</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(3)}
                  >
                    <div>
                      <div className="digit">3</div>
                      <div className="letters">DEF</div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="dailpad-wrapper">
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(4)}
                  >
                    <div>
                      <div className="digit">4</div>
                      <div className="letters">GHI</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-full"
                    onClick={() => this.dailDigit(5)}
                  >
                    <div>
                      <div className="digit">5</div>
                      <div className="letters">JKL</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(6)}
                  >
                    <div>
                      <div className="digit">6</div>
                      <div className="letters">MNO</div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="dailpad-wrapper">
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(7)}
                  >
                    <div>
                      <div className="digit">7</div>
                      <div className="letters">PQRS</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-full"
                    onClick={() => this.dailDigit(8)}
                  >
                    <div>
                      <div className="digit">8</div>
                      <div className="letters">TUV</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={() => this.dailDigit(9)}
                  >
                    <div>
                      <div className="digit">9</div>
                      <div className="letters">WXYZ</div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="dailpad-wrapper">
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                  >
                    <div>
                      <div className="digit">*</div>
                      <div className="letters"></div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-full"
                    onClick={() => this.dailDigit(0)}
                  >
                    <div>
                      <div className="digit">0</div>
                      <div className="letters">+</div>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    className="number-wrapper border-tb"
                    onClick={this.deleteDigits}
                  >
                    <div>
                      <div className="digit">{deleteText}</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
          {withoutCode && (
            <React.Fragment>
              <div className="container">
                <Grid container>
                  <Grid
                    item
                    container
                    alignItems="center"
                    sm={6}
                    className="border padding-l focus-input"
                  >
                    <Grid item xs>
                      <FirstNameIcon
                        className={firstName ? "correctIcon" : ""}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        label={firstNameLabel}
                        onChange={this.handleChange}
                        name="firstName"
                        className={"full-width padding-tb"}
                        type="text"
                        value={firstName}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className="border-left border focus-input"
                    alignItems="center"
                    sm={6}
                  >
                    <Grid item xs>
                      <LastNameIcon className={lastName ? "correctIcon" : ""} />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        label={lastNameLabel}
                        onChange={this.handleChange}
                        name="lastName"
                        className={"full-width padding-tb"}
                        type="text"
                        value={lastName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  className="border padding-l birthdate-verification"
                >
                  <Grid item xs>
                    <DateIcon
                      className={this.state.correctDate ? "correctIcon" : ""}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <FormControl>
                      <InputLabel shrink className="birtbhday-picker-label">
                        {birthdateLabel}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="select-field padding-tb"
                        value={month}
                        onChange={this.handleChangeDOB}
                        displayEmpty
                        inputProps={{
                          name: "month",
                        }}
                      >
                        <MenuItem disabled value="">
                          {this.props.mm || "Month"}
                        </MenuItem>
                        {MonthsArray.map((month) => {
                          return (
                            <MenuItem key={month} value={monthsMap[month]}>
                              {months ? months[month] : monthsMap[month]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="select-field small padding-tb"
                        value={date}
                        onChange={this.handleChangeDOB}
                        displayEmpty
                        inputProps={{
                          name: "date",
                        }}
                      >
                        <MenuItem disabled value="">
                          {this.props.dd || "Date"}
                        </MenuItem>
                        {DatesArray.map((date) => {
                          return (
                            <MenuItem key={date} value={date}>
                              {date}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="select-field small padding-tb"
                        value={year}
                        onChange={this.handleChangeDOB}
                        displayEmpty
                        inputProps={{
                          name: "year",
                        }}
                      >
                        <MenuItem disabled value="">
                          {this.props.yy || "Year"}
                        </MenuItem>
                        {YearsArray.map((year) => {
                          return (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    container
                    alignItems="center"
                    sm={6}
                    className="border padding-l focus-input"
                  >
                    <Grid item xs>
                      <PhoneIcon className={phone ? "correctIcon" : ""} />
                    </Grid>
                    <Grid item xs={10}>
                      <InputMask
                        mask="(999) 999-9999"
                        value={phone}
                        onChange={this.handleChange}
                      >
                        {() => (
                          <TextField
                            label={phoneLabel}
                            onChange={this.handleChange}
                            name="phone"
                            className={"full-width padding-tb"}
                            type="text"
                            value={phone}
                          />
                        )}
                      </InputMask>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    className="border-left border focus-input"
                    alignItems="center"
                    sm={6}
                  >
                    <Grid item xs>
                      <EmailIcon className={email ? "correctIcon" : ""} />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        label={emailLabel}
                        onChange={this.handleChange}
                        name="email"
                        className={"full-width padding-tb"}
                        type="text"
                        value={email}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <div className="code-links">
                  <p className="or">{or || "OR"}</p>
                  <p className="have-a-code" onClick={this.withCode}>
                    {this.props.currLang === "es"
                      ? "¿Cuenta con un código?"
                      : haveCode}
                  </p>
                </div>
                <div className="tc-wrapper">
                  <Checkbox
                    value={agreeTc}
                    color="primary"
                    onChange={this.handleTc}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />{" "}
                  {agreeTo || "I agree to the "}
                  <a target="_blank" href={tcLink}>
                    {termsConditions || "terms & conditions"}
                  </a>
                  <span>*</span>
                </div>
              </div>
            </React.Fragment>
          )}
          {!withoutCode && patientCode.length < 1 && (
            <div className="prefooter">
              <p onClick={this.withoutCode}>
                {this.props.currLang === "es"
                  ? "¿No cuenta con un código?"
                  : doNotHaveCode}
              </p>
            </div>
          )}
          {!withoutCode && patientCode.length > 0 && (
            <footer>
              <div className="row">
                {correctPatientCode ? (
                  <Link
                    to="/birthdate-verification"
                    className="next-btn center"
                  >
                    {continueText}
                    <ArrowForwardIosIcon />
                  </Link>
                ) : (
                    <Link
                      to="/welcome"
                      className="disabled next-btn center"
                      onClick={(event) => event.preventDefault()}
                    >
                      {continueText}
                      <ArrowForwardIosIcon />
                    </Link>
                  )}
              </div>
            </footer>
          )}
          {withoutCode && (
            <footer>
              <div className="row">
                {lastName &&
                  firstName &&
                  agreeTc &&
                  month &&
                  date &&
                  year &&
                  (email || phone) &&
                  !loader ? (
                    <p className="next-btn center" onClick={this.submitForm}>
                      {continueText}
                      <ArrowForwardIosIcon />
                    </p>
                  ) : (
                    <Link
                      to="/welcome"
                      className="disabled next-btn center"
                      onClick={(event) => event.preventDefault()}
                    >
                      {continueText}
                      {!loader ? (
                        <ArrowForwardIosIcon />
                      ) : (
                          <div className="loader-wrapper">
                            <Loader />
                          </div>
                        )}
                    </Link>
                  )}
              </div>
            </footer>
          )}
        </form>
      </React.Fragment>
    );
  }
}

export default LoginCode;
