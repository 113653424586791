import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import  {ReactComponent as CancelIcon}  from '../styles/imgs/Cancel-Icon.svg';


class CancelBtn extends Component {
    state = {  }
    render() {
        return (
            <React.Fragment>
                 <Grid container item spacing={0} justify="flex-end">
                    <div className="cancel-btn">
                        <Link to='/logout'>Cancel <CancelIcon/></Link>
                    </div>
                </Grid>
            </React.Fragment>
        );
    }
}

export default CancelBtn;