import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';

import {ReactComponent as MileStoneIcon} from '../styles/imgs/mileStoneIconHouseHold.svg'; 
import {ReactComponent as MileStone1} from '../styles/imgs/1-milestone.svg'; 
import {ReactComponent as MileStone2} from '../styles/imgs/2-milestone.svg'; 
import {ReactComponent as MileStone3} from '../styles/imgs/3-milestone.svg'; 
import {ReactComponent as MileStone4} from '../styles/imgs/4-milestone.svg'; 
import {ReactComponent as MileStoneLine} from '../styles/imgs/milestone-line.svg'; 

import Logo from "../Components/Logo"
import StepsCounter from "../Components/StepsCounter"

class MilestoneHousehold extends Component {

    goToNextStep = () => {  
        const {history , stepData, globalStateData} = this.props.data;
        const {nextLink, checkFormembers, round} = stepData;     
       
       let roundVal = round
        
        if(checkFormembers){
            const {child, dependants, spouse} = globalStateData.generalInfo.familyMembers; 
    
            let counter = child + dependants + spouse; 
            
            if( round < counter-1){
                this.props.setRound(roundVal+=1);   
                history.push('/household/step1');
                
            }else{
                history.push('/documents/step1')
            }
        }else{
            history.push(nextLink) 
        } 
    }

    render() { 
        const {landData, stepData} =  this.props.data; 
        const {motivationText, callToActionText, aditionalInfo} = landData; 
        const {milestone} = stepData;   

        return ( 
            <React.Fragment>
            <div className="row">
                <div className="mileStone-wrapper border">
                    <div onClick = {this.goToNextStep} className="cursor">
                        <MileStoneIcon className="mainIcon correctIcon"/>
                        <h6>{motivationText}</h6>
                        <h6 className="call-to-action">  
                            {callToActionText}  
                        </h6>
                        <div className="steps-wrapper">
                            <MileStone1 className={milestone>=1 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=2 ? 'correctIcon':'')}/>
                            <MileStone2 className={milestone>=2 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=3 ? 'correctIcon':'')}/>
                            <MileStone3 className={milestone>=3 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=4 ? 'correctIcon':'')}/>
                            <MileStone4 className={milestone>=4 ? 'correctIcon':''}/>
                        </div>
                        <p className="aditionalInfo">{aditionalInfo}</p>
                    </div>
                </div>
            </div>
            </React.Fragment>
         );
    }
}
 
export default MilestoneHousehold;