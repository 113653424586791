import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { ReactComponent as DeleteIcon } from "../../styles/imgs/deleteIcon.svg";
import { ReactComponent as ArrowForwardIosIcon } from "../../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../../styles/imgs/arrow-icon-left.svg";
import { Divider } from "@material-ui/core";

class Photos extends Component {
  state = {
    photoSsc: "",
    photoSscSize: "",
    photoId: "",
    photoIdSize: "",
    photoPi: "",
    photoPiSize: "",
    photoPR: "",
    photoPRSize: "",
  };
  goToNextStep = () => {
    const { history, stepData } = this.props.data;
    const { nextLink } = stepData;
    history.push(nextLink);
  };

  handlePhotoSend = (event) => {
    this.props.handlePhotoSend(event);
  };
  removePhoto = (name) => {
    this.props.removePhoto(name);
  };

  componentDidUpdate = () => {
    // console.log(this.props);
    // if (this.props.clearPhotos) {
    //   this.props;
    // }
  };

  render() {
    const { landData, stepData, stateData } = this.props.data;
    const {
      noDocunmentsText,
      callToActionText,
      sscText,
      IdText,
      piText,
      upload,
      prText,
      additionalDocumentsText,
      additionalDocumentsYesText,
      additionalDocumentsNoText,
    } = landData;

    const {
      photoSsc,
      photoSscSize,
      photoId,
      photoIdSize,
      photoPi,
      photoPiSize,
      photoPR,
      photoPRSize,
    } = stateData;

    return (
      <React.Fragment>
        <div className="photos-wrapper border-b">
          <Grid
            container
            className={
              "row border input-holder " + (photoId ? "image-added" : "")
            }
          >
            <Grid
              container
              item
              xs={8}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                {photoId && <img className="photo" src={photoId} />}
              </Grid>
              <Grid container item xs justify="flex-start" direction="column">
                <h5 className="title">{IdText}</h5>
                {photoId && <p className="photo-size">{photoIdSize}</p>}
              </Grid>
            </Grid>
            <Grid container item xs={4} justify="flex-end" alignItems="center">
              {!photoId && (
                <div>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="photoId"
                    name="photoId"
                    type="file"
                    onChange={this.handlePhotoSend}
                  />
                  <label htmlFor="photoId">{upload || "Upload"}</label>
                </div>
              )}
              {photoId && (
                <div
                  onClick={() => this.removePhoto("photoId")}
                  ref={(el) => (this.refPhotoId = el)}
                >
                  <DeleteIcon className="cursor" />
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            className={
              "row border input-holder " + (photoSsc ? "image-added" : "")
            }
          >
            <Grid
              container
              item
              xs={8}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                {photoSsc && <img className="photo" src={photoSsc} />}
              </Grid>
              <Grid container item xs justify="flex-start" direction="column">
                <h5 className="title">{sscText}</h5>
                {photoSscSize && <p className="photo-size">{photoSscSize}</p>}
              </Grid>
            </Grid>
            <Grid container item xs={4} justify="flex-end" alignItems="center">
              {!photoSsc && (
                <div>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="photoSsc"
                    name="photoSsc"
                    type="file"
                    onChange={this.handlePhotoSend}
                  />
                  <label htmlFor="photoSsc">{upload || "Upload"}</label>
                </div>
              )}
              {photoSsc && (
                <div
                  onClick={() => this.removePhoto("photoSsc")}
                  ref={(el) => (this.refPhotoSsc = el)}
                >
                  <DeleteIcon className="cursor" />
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            className={
              "row border input-holder " + (photoPi ? "image-added" : "")
            }
          >
            <Grid
              container
              item
              xs={8}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                {photoPi && <img className="photo" src={photoPi} />}
              </Grid>
              <Grid container item xs justify="flex-start" direction="column">
                <h5 className="title">{piText}</h5>
                {photoPi && <p className="photo-size">{photoPiSize}</p>}
              </Grid>
            </Grid>
            <Grid container item xs={4} justify="flex-end" alignItems="center">
              {!photoPi && (
                <div>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="photoPi"
                    name="photoPi"
                    type="file"
                    onChange={this.handlePhotoSend}
                  />
                  <label htmlFor="photoPi">{upload || "Upload"}</label>
                </div>
              )}
              {photoPi && (
                <div
                  onClick={() => this.removePhoto("photoPi")}
                  ref={(el) => (this.refPhotoPi = el)}
                >
                  <DeleteIcon className="cursor" />
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            className={
              "row border input-holder " + (photoPR ? "image-added" : "")
            }
          >
            <Grid
              container
              item
              xs={8}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                {photoPR && <img className="photo" src={photoPR} />}
              </Grid>
              <Grid container item xs justify="flex-start" direction="column">
                <h5 className="title">{prText || "Proof of Residence"}</h5>
                {photoPR && <p className="photo-size">{photoPRSize}</p>}
              </Grid>
            </Grid>
            <Grid container item xs={4} justify="flex-end" alignItems="center">
              {!photoPR && (
                <div>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="photoPR"
                    name="photoPR"
                    type="file"
                    onChange={this.handlePhotoSend}
                  />
                  <label htmlFor="photoPR">{upload || "Upload"}</label>
                </div>
              )}
              {photoPR && (
                <div
                  onClick={() => this.removePhoto("photoPR")}
                  ref={(el) => (this.refPhotoPR = el)}
                >
                  <DeleteIcon className="cursor" />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="row pre-footer" onClick={this.goToNextStep}>
          <h6>{noDocunmentsText}</h6>
          <h6 className="call-to-action">{callToActionText}</h6>
        </div>
        {this.props.confirmNewDocuments && (
          <div className="mask-new-documents">
            <div className="mask-new-documents-body">
              <h4 className="special-title">
                {additionalDocumentsText || `Do you have more files to upload?`}
              </h4>
              <div className="btns-wrapper">
                <div
                  className="btn-yes"
                  onClick={() => {
                    this.props.closeConfirmNewDocuments();
                    this.removePhoto("photoId");
                    this.removePhoto("photoSsc");
                    this.removePhoto("photoPi");
                    this.removePhoto("photoPR");
                    setTimeout(() => {
                      this.refPhotoId && this.refPhotoId.click();
                    }, 1);
                    setTimeout(() => {
                      this.refPhotoSsc && this.refPhotoSsc.click();
                    }, 1);
                    setTimeout(() => {
                      this.refPhotoPi && this.refPhotoPi.click();
                    }, 1);
                    setTimeout(() => {
                      this.refPhotoPR && this.refPhotoPR.click();
                    }, 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  <ArrowBackIosIcon />
                  <span>{additionalDocumentsYesText || `Yes, I have`}</span>
                </div>
                <div
                  className="btn-no"
                  onClick={() => {
                    this.props.history.push("/documents/step2");
                    this.props.closeConfirmNewDocuments();
                  }}
                >
                  <span>{additionalDocumentsNoText || `No, continue`}</span>
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Photos);
