import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import LinearProgress from "@material-ui/core/LinearProgress";

class FooterProgressBar extends Component {
  checkValidationEmp = (obj) => {
    for (var key in obj) {
      for (var v in obj[key]) {
        if (obj[key].name == "" || obj[key].value == "") {
          return false;
        }
        // if (obj[key][v] == '' || obj[key][v] == null){
        //     return false;
        // }
      }
    }
    return true;
  };

  checkValidationCombined = (obj) => {
    const { stepData, stateData } = this.props.data;
    const { stateList, round } = stepData;
    let { [stateList]: stateL } = stateData[round].financialInfo;

    if (obj.length > 0) {
      let currentObj = obj[obj.length - 1];
      if (
        this.checkValidationList(stateL) &&
        currentObj.name == "" &&
        currentObj.value == ""
      ) {
        // return true;
        return false;
      } else if (
        this.checkValidationList(stateL) &&
        !currentObj.name == "" &&
        !currentObj.value == ""
      ) {
        return true;
      } else if (!currentObj.name == "" && !currentObj.value == "") {
        return true;
      }
    } else {
      if (this.checkValidationList(stateL)) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkValidationList = (obj) => {
    for (var key in obj) {
      for (var v in obj[key]) {
        if (obj[key][v] !== "" || obj[key][v] !== null) {
          return true;
        }
      }
    }
    return false;
  };

  goToPrevStep = () => {
    const { history, stepData, stateData, globalStateData } = this.props.data;
    const { prevLink, round, editPrevious, checkIsChild, view } = stepData;

    let roundVal = round;
    if (editPrevious && roundVal > 0) {
      this.props.setRound((roundVal -= 1));
      if (
        new Date().getFullYear() -
          globalStateData.houseHoldInfo[round - 1].personData.birthday.year <=
        12
      ) {
        history.push("/household/step1");
      } else {
        history.push("/household/step4");
      }
    } else if (checkIsChild && (view == "milestone" || view == "memberData")) {
      if (
        new Date().getFullYear() -
          globalStateData.houseHoldInfo[round].personData.birthday.year <=
        12
      ) {
        if (roundVal > 0) {
          history.push("/household/step1");
        } else {
          history.push(prevLink);
        }
      } else {
        history.push(prevLink);
      }
    } else {
      history.push(prevLink);
    }
  };

  sendToApi = (url) => {
    const { stateElement } = this.props.data.stepData;
    if (!this.props.loading) {
      this.props.sendToApi(stateElement, "household", () => {
        this.props.history.push(url);
      });
    }
  };

  goToNextStep = () => {
    const { history, stepData, globalStateData, stateData } = this.props.data;
    const {
      nextLink,
      checkFormembers,
      round,
      view,
      checkIsChild,
      stateElement,
    } = stepData;

    let roundVal = round;
    if (checkFormembers) {
      const {
        child,
        dependants,
        spouse,
      } = globalStateData.generalInfo.familyMembers;
      let counter = child + dependants + spouse;

      if (round < counter - 1) {
        this.props.setRound((roundVal += 1));
        this.sendToApi("/household/step1");
      } else {
        this.sendToApi("/household/step5");
      }
    } else if (checkIsChild) {
      if (view == "memberData") {
        const {
          child,
          dependants,
          spouse,
        } = globalStateData.generalInfo.familyMembers;
        let counter = child + dependants + spouse;

        if (
          new Date().getFullYear() -
            globalStateData.houseHoldInfo[round].personData.birthday.year <=
          12
        ) {
          if (round < counter - 1) {
            this.props.setRound((roundVal += 1));
            this.sendToApi("/household/step1");
          } else {
            this.sendToApi("/household/step5");
          }
        } else {
          this.sendToApi(nextLink);
        }
      } else {
        this.sendToApi(nextLink);
      }
    } else {
      this.sendToApi(nextLink);
    }
  };

  render() {
    const { loading } = this.props;
    const { landData, stepData, stateData } = this.props.data;

    const { nextBtn, prevBtn, endSession } = landData;
    const {
      prevLink,
      nextLink,
      completed,
      stateElement,
      stateObjName,
      stateList,
      view,
      round,
    } = stepData;

    let { [stateElement]: stateElementVal } = stateData[round].financialInfo;
    let { [stateObjName]: stateObjVal } = stateData[round].financialInfo;
    let { [stateList]: stateL } = stateData[round].financialInfo;

    const {
      firstName,
      gender,
      lastName,
      relationship,
      socialSecurityNumber,
      birthday,
    } = stateData[round].personData;
    const { month, date, year } = birthday;

    return (
      <footer className={loading ? "loading" : ""}>
        <LinearProgress variant="determinate" value={completed} />
        <div className="row">
          <Grid container>
            <Grid container item xs justify="flex-start">
              <div
                onClick={() => {
                  if (!loading) {
                    this.goToPrevStep();
                  }
                }}
                className="prev-btn"
              >
                <div className="footer-loader-wrapper left">
                  <ArrowBackIosIcon />
                  <span>{prevBtn}</span>
                </div>
              </div>
            </Grid>
            <Grid container item xs justify="center">
              <Link
                to="/logout"
                className="end-sesion-btn"
                onClick={(e) => {
                  if (loading) {
                    e.preventDefault();
                  }
                }}
              >
                {" "}
                {endSession}{" "}
              </Link>
            </Grid>
            <Grid container item xs justify="flex-end">
              <span className="hidden">
                {stateElement == "employed"
                  ? stateElementVal == "yes"
                    ? (stateElementVal = this.checkValidationEmp(stateObjVal))
                    : ""
                  : ""}
                {stateElement == "gender"
                  ? stateElementVal
                    ? (stateElementVal = true)
                    : ""
                  : ""}
                {stateElement == "expenses" || stateElement == "income"
                  ? stateElementVal == "yes"
                    ? (stateElementVal = this.checkValidationCombined(
                        stateObjVal
                      ))
                    : ""
                  : ""}

                {stateElement == "milestone" ? (stateElementVal = "true") : ""}
                {view == "memberData"
                  ? firstName && lastName
                    ? (stateElementVal = true)
                    : ""
                  : ""}
              </span>
              {stateElementVal ? (
                <div
                  onClick={() => {
                    if (!loading) {
                      this.goToNextStep();
                    }
                  }}
                  className="next-btn"
                >
                  <div className="footer-loader-wrapper">
                    <span>{nextBtn}</span>

                    {loading ? <Loader /> : <ArrowForwardIosIcon />}
                  </div>
                </div>
              ) : (
                <div
                  className="disabled next-btn"
                  onClick={(event) => event.preventDefault()}
                >
                  <div className="footer-loader-wrapper">
                    <span>{nextBtn}</span>

                    {loading ? <Loader /> : <ArrowForwardIosIcon />}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </footer>
    );
  }
}

export default withRouter(FooterProgressBar);
