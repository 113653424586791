import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import LinearProgress from "@material-ui/core/LinearProgress";

class FooterProgressBarDocuments extends Component {
  goToPrevStep = () => {
    const { history, stepData, globalStateData } = this.props.data;
    const { prevLink, editPrevious } = stepData;

    if (editPrevious) {
      const {
        child,
        dependants,
        spouse,
      } = globalStateData.generalInfo.familyMembers;

      if (child > 0 || dependants > 0 || spouse > 0) {
        history.push(prevLink);
      } else {
        history.push("/financial-info/step5");
      }
    } else {
      history.push(prevLink);
    }
  };

  sendToApi = (url) => {
    if (!this.props.loading) {
      this.props.sendToApi(
        null,
        null,
        () => {
          this.props.history.push(url);
        },
        url === "/documents/step2"
      );
    }
  };

  goToNextStep = () => {
    const { history, stepData } = this.props.data;
    const { nextLink } = stepData;
    if (this.props.location.pathname !== "/documents/step1") {
      this.sendToApi(nextLink);
    } else {
      this.props.history.push("/documents/step2");
    }
  };

  render() {
    const { loading } = this.props;
    const { landData, stepData, stateData, globalStateData } = this.props.data;

    const { nextBtn, prevBtn, endSession } = landData;
    const { completed, stateElement, hideExtra } = stepData;

    let { [stateElement]: stateElementVal } = stateData;

    return (
      <footer className={loading ? "loading" : ""}>
        <LinearProgress variant="determinate" value={completed} />
        {stateElement !== "contacts" && (
          <div className="row">
            <Grid container>
              <Grid container item xs justify="flex-start">
                <div
                  onClick={() => {
                    if (!loading) {
                      this.goToPrevStep();
                    }
                  }}
                  className="prev-btn"
                >
                  <div className="footer-loader-wrapper left">
                    <ArrowBackIosIcon />
                    <span>{prevBtn}</span>
                  </div>
                </div>
              </Grid>
              <Grid container item xs justify="center">
                <Link
                  to="/logout"
                  className="end-sesion-btn"
                  onClick={(e) => {
                    if (loading) {
                      e.preventDefault();
                    }
                  }}
                >
                  {" "}
                  {endSession}{" "}
                </Link>
              </Grid>
              <Grid container item xs justify="flex-end">
                <span className="hidden">
                  {stateElement == "photos" ? (stateElementVal = "true") : ""}
                </span>
                {stateElementVal ? (
                  <div
                    onClick={() => {
                      if (!loading) {
                        this.goToNextStep();
                      }
                    }}
                    className="next-btn"
                  >
                    <div className="footer-loader-wrapper">
                      <span>{nextBtn}</span>
                      {loading &&
                      this.props.location.pathname !== "/documents/step1" ? (
                        <Loader />
                      ) : (
                        <ArrowForwardIosIcon />
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="disabled next-btn"
                    onClick={(event) => event.preventDefault()}
                  >
                    <div className="footer-loader-wrapper">
                      <span>{nextBtn}</span>
                      {loading ? <Loader /> : <ArrowForwardIosIcon />}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {stateElement == "contacts" && (
          <div className="row">
            {stateData.email || stateData.phone || stateData.message ? (
              <div
                onClick={() => {
                  if (!loading) {
                    this.goToNextStep();
                  }
                }}
                className={"next-btn center"}
              >
                <div className="footer-loader-wrapper">
                  <span>{nextBtn}</span>
                  {loading ? <Loader /> : <ArrowForwardIosIcon />}
                </div>
              </div>
            ) : (
              <div
                className="disabled next-btn center"
                onClick={(event) => event.preventDefault()}
              >
                {nextBtn}
                <ArrowForwardIosIcon />
              </div>
            )}
          </div>
        )}
      </footer>
    );
  }
}

export default withRouter(FooterProgressBarDocuments);
