import React, { Component } from 'react'; 

import CheckboxDropdown from '../../Components/CheckboxDropdown'
import ChecBoxRowTemplate from '../../Components/CheckBoxRowTemplate'
import AditionalInputsTemplate from '../../Components/AditionalInputsTemplate'

class Assets extends Component { 
    handleCheckboxDropdown = (state, value) =>{this.props.handleCheckboxDropdown(state, value)}   
    handleIncomeChange=(event,stateForUpdate) => {this.props.handleIncomeChange(event,stateForUpdate)}

    handleRowClick = (inputName,stateForUpdate,val) => {this.props.handleRowClick(inputName,stateForUpdate,val)}

    addRow = (event, stateObjName) => {this.props.addRow(event, stateObjName)}
    handleName = (event, index,stateObjName) =>{this.props.handleName(event, index, stateObjName)}
    handleAmount = (event, index,stateObjName) =>{ this.props.handleAmount(event, index, stateObjName)}
    removeRow = (index,stateObjName) => {this.props.removeRow(index,stateObjName)}


    render() { 
        const {landData, stepData, stateData} =  this.props.data 
    
        const {bankAccount, home, vehicle, monthyAmountText, monthyAmountPlaceholder, others,othersPlaceholder, addText} = landData; 
        const {stateElement, stateObjName} = stepData;  
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal, assetsList} = stateData;    
       
        let propsObj = {landData, stepData, stateData}
        return ( 
            <React.Fragment>
                    <CheckboxDropdown 
                    data = {propsObj} 
                    handleCheckboxDropdown = {this.handleCheckboxDropdown} />
                    {stateElementVal=='yes' &&
                    <div>
                        <ChecBoxRowTemplate 
                            text={bankAccount}
                            monthyAmountText={monthyAmountText}
                            monthyAmountPlaceholder={monthyAmountPlaceholder}
                            inputName='bankAccount'
                            inputValue = {assetsList.bankAccount} 
                            stateForUpdate = 'assetsList'
                            handleIncomeChange = {this.handleIncomeChange}
                            handleRowClick = {this.handleRowClick}
                            
                        />     
                        <ChecBoxRowTemplate 
                            text={home}
                            monthyAmountText={monthyAmountText}
                            monthyAmountPlaceholder={monthyAmountPlaceholder}
                            inputName='home'
                            inputValue = {assetsList.home} 
                            stateForUpdate = 'assetsList'
                            handleIncomeChange = {this.handleIncomeChange}
                            handleRowClick = {this.handleRowClick}
                        
                        />     
                        <ChecBoxRowTemplate 
                            text={vehicle}
                            monthyAmountText={monthyAmountText}
                            monthyAmountPlaceholder={monthyAmountPlaceholder}
                            inputName='vehicle'
                            inputValue = {assetsList.vehicle} 
                            stateForUpdate = 'assetsList'
                            handleIncomeChange = {this.handleIncomeChange}
                            handleRowClick = {this.handleRowClick}
                            
                        />   
                    </div>  
                     } 
                    <AditionalInputsTemplate
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal  = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={others}
                        namePlaceholder={othersPlaceholder} 
                        amountLabel ={monthyAmountText}
                        monthyAmountPlaceholder ={monthyAmountPlaceholder} 
                        addText = {addText}
                        addRow = {this.addRow}
                        handleName = {this.handleName}
                        handleAmount = {this.handleAmount}
                        removeRow = {this.removeRow} 
                    />
               
            </React.Fragment> 
        );
    }
}
 
export default Assets;