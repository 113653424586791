import React, { Component } from 'react'; 

import Grid from '@material-ui/core/Grid';
  
import {ReactComponent as GenderFemale} from '../../styles/imgs/genderFemale.svg';
import {ReactComponent as GenderMale} from '../../styles/imgs/genderMale.svg'; 

import Logo from "../../Components/Logo"
import StepsCounter from "../../Components/StepsCounter"

class GIStepGender extends Component {
    
    handleCheckboxClick = (state, value) =>{ 
        this.props.handleCheckboxClick(state, value)
    }

    render() {  
            const {landData, stepData, stateData} =  this.props.data;

            const {yesText, noText} = landData; 
            const {stateElement} = stepData; 
            let { [stateElement]:stateElementVal} = stateData;   
     
             
            return (  
                <React.Fragment> 
                    <div className="container">
                        <Grid container>
                            <Grid direction="column" container item xs alignItems="center" justify="center" 
                            className={"checkbox-container border-right " + (stateElementVal=='Female' ? 'checked':'')} 
                            onClick={()=>this.handleCheckboxClick({stateElement} ,'Female')}>
                                <GenderFemale/> 
                                <h4 className="checkbox-label">{yesText}</h4>
                            </Grid>
                            <Grid direction="column" container item xs alignItems="center" justify="center" 
                            className={"checkbox-container " + (stateElementVal=='Male' ? 'checked':'')} 
                            onClick={()=>this.handleCheckboxClick({stateElement} ,'Male')}>
                                <GenderMale/>
                                <h4 className="checkbox-label">{noText}</h4>
                            </Grid>
                        </Grid>   
                    </div>
                </React.Fragment> 
            );
    }
}
 
export default GIStepGender;