import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as DateIcon } from "../styles/imgs/birthdayIcon.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";

import Logo from "../Components/Logo";
import CancelBtn from "../Components/CancelBtn";

import MonthsArray from "../json/Months";
import DatesArray from "../json/Dates";
import YearsArray from "../json/Years";

import { apiUrl } from "../Helpers/urls";

const monthsMap = {
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  june: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December",
};

class BirthdateVerification extends Component {
  state = {
    submitting: false,
    correctDate: false,
    birthday: {
      month: "",
      date: "",
      year: "",
    },
    loader: false,
  };
  handleChange = (event) => {
    this.setState({
      ...this.state,
      birthday: {
        ...this.state.birthday,
        [event.target.name]: event.target.value.toString(),
      },
    });
    this.props.handleChangeDate(event);
  };
  submitData = (event) => {
    this.setState({ loader: true });

    const { firstName, lastName } = this.props.data.personData;
    this.props.handleChangeDate(event);
    this.props.loginSuccess();
    this.props.startLoading();

    let data;
    if (this.props.match.params.id) {
      data = {
        benefitRecordId: this.props.match.params.id,
        personData: {
          birthday: this.state.birthday,
        },
        lastCompletedStep: window.location.pathname,
        outreachRequestId: this.props.outreachRequestId,
        src: this.props.src
      };
    } else {
      data = {
        accessCode: this.props.accessCode,
        benefitRecordId: "00000000-0000-0000-0000-000000000000",
        personData: {
          firstName,
          lastName,
          birthday: this.state.birthday,
        },
        lastCompletedStep: window.location.pathname,
        outreachRequestId: this.props.outreachRequestId,
        src: this.props.src
      };
    }
    const url = apiUrl
    if(data.outreachRequestId == "" || null || undefined) { data.outreachRequestId = "00000000-0000-0000-0000-000000000000"}
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": "B9C1A34C-D284-4596-B0B5-478FF3E46E0D",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (
          result == "Error: Access Denied" ||
          result == "Error: Error: Access Denied"
        ) {
          this.props.loginFail();
          this.setState({
            loader: false,
            correctDate: false,
            loginFail: true,
          });
        } else {
          this.setState({
            loader: false,
          });
          this.props.loginSuccess();
          if (this.props.match.params.id) {
            sessionStorage.setItem(
              "paramsId",
              JSON.stringify(this.props.match.params.id)
            );
          }
          this.props.setToState(result);
          this.props.setLangCode(result.languageCode);
          const { history } = this.props;
          history.push("/welcome");
        }
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.props.match.params.outreachRequestId !== undefined || null || "") {
      sessionStorage.setItem("outreachRequestId", JSON.stringify(this.props.match.params.outreachRequestId));
      this.props.setOutreachRequestId(JSON.stringify(this.props.match.params.outreachRequestId));
    }
    if (this.props.match.params.src !== undefined || null || "") {
      sessionStorage.setItem("src", JSON.stringify(this.props.match.params.src));
      this.props.setSrc(JSON.stringify(this.props.match.params.src));
    }
  };

  render() {
    const {
      mainTitle,
      formTitle,
      continueText,
      mm,
      dd,
      yy,
      newCodeMsg,
    } = this.props.landData;
    const { month, date, year } = this.state.birthday;
    const { loader } = this.state;
    const { altLang, logoUrl, setLangCode, languageCode, months } = this.props;

    return (
      <React.Fragment>
        <div className="top-section bottom-space">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">{mainTitle}</h2>
          </div>
        </div>
        <div className="row border-tb birthdate-verification">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs>
              <DateIcon
                className={this.state.correctDate ? "correctIcon" : ""}
              />
            </Grid>
            <Grid item xs={11}>
              <FormControl>
                <InputLabel shrink className="birtbhday-picker-label">
                  {formTitle}
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="select-field padding-tb"
                  value={month}
                  onChange={this.handleChange}
                  displayEmpty
                  inputProps={{
                    name: "month",
                  }}
                >
                  <MenuItem value="">
                    {this.props.mm || "Month"}
                  </MenuItem>
                  {MonthsArray.map((month) => {
                    return (
                      <MenuItem key={month} value={monthsMap[month]}>
                        {months ? months[month] : monthsMap[month]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="select-field small padding-tb"
                  value={date}
                  onChange={this.handleChange}
                  displayEmpty
                  inputProps={{
                    name: "date",
                  }}
                >
                  <MenuItem disabled value="">
                    {this.props.dd || "Date"}
                  </MenuItem>
                  {DatesArray.map((date) => {
                    return (
                      <MenuItem key={date} value={date}>
                        {date}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    getContentAnchorEl: null,
                  }}
                  className="select-field small padding-tb"
                  value={year}
                  onChange={this.handleChange}
                  displayEmpty
                  inputProps={{
                    name: "year",
                  }}
                >
                  <MenuItem disabled value="">
                    {this.props.yy || "Year"}
                  </MenuItem>
                  {YearsArray.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <footer>
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: this.state.loginFail
                  ? "space-between"
                  : "center",
                padding: "0 20px",
              }}
            >
              {month && date && year && !loader ? (
                <>
                  {this.state.loginFail && (
                    <div
                      onClick={() => {
                        this.props.loginSuccess();
                        this.props.history.push({
                          pathname: "/",
                          state: { fromBirthdateVerification: true },
                        });
                      }}
                      className="prev-btn noTransition"
                      style={{
                        width: "auto",
                      }}
                    >
                      <ArrowBackIosIcon />
                      {this.props.newCodeMsg || "Enter new code"}
                    </div>
                  )}
                  <div
                    to="/information-review"
                    onClick={this.submitData}
                    className="next-btn noTransition"
                    style={{
                      width: this.state.loginFail ? "auto" : "100%",
                      paddingRight: this.state.loginFail ? "4vw" : "0",
                      justifyContent: this.state.loginFail
                        ? "flex-end"
                        : "center",
                    }}
                  >
                    {" "}
                    {continueText}
                    {!loader ? (
                      <ArrowForwardIosIcon />
                    ) : (
                        <div className="loader-wrapper">
                          <Loader />
                        </div>
                      )}
                  </div>
                </>
              ) : (
                  <>
                    {this.state.loginFail && <div>&nbsp;</div>}
                    <div
                      to="/information-review"
                      className="disabled next-btn"
                      style={{
                        width: this.state.loginFail ? "auto" : "100%",
                        paddingRight: this.state.loginFail ? "4vw" : "0",
                        justifyContent: this.state.loginFail
                          ? "flex-end"
                          : "center",
                      }}
                    >
                      {continueText}
                      {!loader ? (
                        <ArrowForwardIosIcon />
                      ) : (
                          <div className="loader-wrapper">
                            <span
                              style={{
                                position: "absolute",
                                left: "7px",
                                top: "-3px",
                              }}
                            >
                              <Loader />
                            </span>
                          </div>
                        )}
                    </div>
                  </>
                )}
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default withRouter(BirthdateVerification);
