import React, { Component } from 'react'; 

import CheckboxDropdownHousehold  from '../../Components/CheckboxDropdownHousehold'
import AditionalInputsTemplateHousehold  from '../../Components/AditionalInputsTemplateHousehold'

class Employers extends Component { 

    componentDidMount = () =>{
        const {stepData} =  this.props.data; 
        const {round} = stepData; 
        this.props.generateEmptyEmployerObjHouseHold(round);
    }
 
    handleCheckboxDropdownHousehold = (state, value, round) =>{this.props.handleCheckboxDropdownHousehold(state, value, round)}  

    addRowHousehold = (event, stateObjName,round) => {this.props.addRowHousehold(event, stateObjName,round)}
    handleNameHousehold = (event, index,stateObjName, round) =>{this.props.handleNameHousehold(event, index, stateObjName, round)}
    handleAmountHousehold = (event, index, stateObjName, round) =>{ this.props.handleAmountHousehold(event, index, stateObjName, round)}
    removeRowHousehold = (index,stateObjName, round) => {this.props.removeRowHousehold(index,stateObjName,round)}

    render() { 
        const {landData, stepData, stateData} =  this.props.data; 
        
        const {employerNameText, monthyIncomeText, addText, monthyIncomePlaceholder, employerNamePlacehoder} = landData; 
        const {stateElement, stateObjName, round} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal} =  stateData[round].financialInfo;
        
        let propsObj = {landData, stepData, stateData}

        return ( 
            <React.Fragment>
                <CheckboxDropdownHousehold 
                    data = {propsObj} 
                    handleCheckboxDropdownHousehold = {this.handleCheckboxDropdownHousehold} />
                 <AditionalInputsTemplateHousehold 
                        round ={round}
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal  = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={employerNameText}
                        namePlaceholder={employerNamePlacehoder} 
                        amountLabel ={monthyIncomeText}
                        monthyAmountPlaceholder ={monthyIncomePlaceholder} 
                        addText = {addText}
                        addRowHousehold = {this.addRowHousehold}
                        handleNameHousehold = {this.handleNameHousehold}
                        handleAmountHousehold = {this.handleAmountHousehold}
                        removeRowHousehold = {this.removeRowHousehold} 
                    />
            </React.Fragment> 
        );
    }
}
 
export default Employers;