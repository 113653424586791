import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import {ReactComponent as CheckBoxYes} from '../../styles/imgs/checkbox-yes.svg';
import {ReactComponent as CheckBoxNo} from '../../styles/imgs/checkbox-no.svg';

import Logo from "../../Components/Logo"
import StepsCounter from "../../Components/StepsCounter"

class GIStepCheckBox extends Component {
   
    handleCheckboxClick = (state, value) =>{ 

        this.props.handleCheckboxClick(state, value)
    }

    render() {  
          
            const {landData, stepData, stateData} =  this.props.data;

            const {yesText, noText} = landData; 
            const {stateElement} = stepData; 
            let { [stateElement]:stateElementVal} = stateData;   
            
            return (  
                <React.Fragment>
                     
                   <div className="container">
                    <Grid container>
                            <Grid direction="column" container item xs alignItems="center" justify="center" 
                            className={"checkbox-container border-right " + (stateElementVal=='yes' ? 'checked':'')} 
                            onClick={()=>this.handleCheckboxClick({stateElement} ,'yes')}>
                                <CheckBoxYes/> 
                                <h4 className="checkbox-label">{yesText}</h4>
                            </Grid>
                            <Grid direction="column" container item xs alignItems="center" justify="center" 
                            className={"checkbox-container " + (stateElementVal=='no' ? 'checked':'')} 
                            onClick={()=>this.handleCheckboxClick({stateElement} ,'no')}>
                                <CheckBoxNo/>
                                <h4 className="checkbox-label">{noText}</h4>
                            </Grid>
                        </Grid>
                   </div>
                    
                </React.Fragment> 
            );
    }
}
 
export default GIStepCheckBox;