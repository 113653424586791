import React, { Component } from 'react'; 

import CheckboxDropdownHousehold from '../../Components/CheckboxDropdownHousehold'
import CheckBoxRowTemplateHousehold from '../../Components/CheckBoxRowTemplateHousehold'
import AditionalInputsTemplateHousehold from '../../Components/AditionalInputsTemplateHousehold'

class Income extends Component { 
    handleCheckboxDropdownHousehold = (state, value, round) =>{this.props.handleCheckboxDropdownHousehold(state, value, round)}  
    handleIncomeChangeHousehold=(event,stateForUpdate, round) => {this.props.handleIncomeChangeHousehold(event,stateForUpdate, round)} 
  
    handleRowClickHousehold = (inputName,stateForUpdate,val, round) => {this.props.handleRowClickHousehold(inputName,stateForUpdate,val, round)}

    addRowHousehold = (event, stateObjName, round) => {this.props.addRowHousehold(event, stateObjName,round)}
    handleNameHousehold = (event, index,stateObjName, round) =>{this.props.handleNameHousehold(event, index, stateObjName,round)}
    handleAmountHousehold = (event, index, stateObjName, round) =>{ this.props.handleAmountHousehold(event, index, stateObjName, round)}
    removeRowHousehold = (index,stateObjName,round) => {this.props.removeRowHousehold(index,stateObjName,round)}


    render() { 
        const {landData, stepData, stateData} =  this.props.data 
        
        const {unemployment,socialSecurity,pensions,retirementAccounts,alimony,childSupport, monthyAmountText, addText, monthyAmountPlaceholder, others,othersPlaceholder, employerNamePlacehoder} = landData; 
        const {stateElement, stateObjName, round} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal, incomeList} = stateData[round].financialInfo; 
        
        
        
        let propsObj = {landData, stepData, stateData}
        return ( 
            <React.Fragment>  
                <CheckboxDropdownHousehold 
                    data = {propsObj} 
                    handleCheckboxDropdownHousehold = {this.handleCheckboxDropdownHousehold} />
                    {stateElementVal=='yes' &&
                        <div>
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={pensions}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='pensions'
                                inputValue = {incomeList.pensions} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />  
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={socialSecurity}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='socialSecurity'
                                inputValue = {incomeList.socialSecurity} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />  
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={retirementAccounts}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='retirementAccounts'
                                inputValue = {incomeList.retirementAccounts} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={unemployment}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='unemployment'
                                inputValue = {incomeList.unemployment} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />     
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={alimony}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='alimony'
                                inputValue = {incomeList.alimony} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={childSupport}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='childSupport'
                                inputValue = {incomeList.childSupport} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />
                          
                           
                           
                            
                              
                              
                           
                         
                        </div>      
                    }
                    <AditionalInputsTemplateHousehold 
                        round = {round}
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal  = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={others}
                        namePlaceholder={othersPlaceholder} 
                        amountLabel ={monthyAmountText}
                        monthyAmountPlaceholder ={monthyAmountPlaceholder} 
                        addText = {addText}
                        addRowHousehold = {this.addRowHousehold}
                        handleNameHousehold = {this.handleNameHousehold}
                        handleAmountHousehold = {this.handleAmountHousehold}
                        removeRowHousehold = {this.removeRowHousehold} 
                    />
               
            </React.Fragment> 
        );
    }
}
 
export default Income;