import React, { Component } from "react";

import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as ArrowForwardIosIcon } from "../styles/imgs/arrow-icon-right.svg";
import { ReactComponent as ArrowBackIosIcon } from "../styles/imgs/arrow-icon-left.svg";
import { ReactComponent as Loader } from "../styles/imgs/loader.svg";
import SignatureCanvas from "react-signature-canvas";

import Logo from "../Components/Logo";
import CancelBtn from "../Components/CancelBtn";

class Signature extends Component {
  state = {
    background: true,
  };

  startSignature = () => {
    this.setState({ background: false });
  };

  sigPad = {};

  saveSignature = () => {
    let signarture = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    this.props.saveSignature(signarture);
  };

  clearignature = () => {
    this.sigPad.clear();
    this.setState({ background: true });
    this.props.clearignature();
  };

  sendToApi = (url) => {
    if (!this.props.loading) {
      this.props.sendToApi(null, null, () => {
        this.props.history.push(url);
      });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      mainTitle,
      intoText,
      clearBtn,
      formTitle,
      prevBtn,
      endSession,
      nextBtn,
    } = this.props.landData;
    const { signature } = this.props.globalStateData.documents;
    const { altLang, logoUrl, setLangCode, languageCode, loading } = this.props;

    return (
      <React.Fragment>
        <div className="top-section bottom-space">
          <header>
            <div className="row">
              <Grid container spacing={0}>
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title">{mainTitle}</h2>
            <p className="text-intro">{intoText}</p>
          </div>
        </div>

        <div className="row">
          <h3 className="form-title">{formTitle}</h3>
        </div>
        <div className="row">
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              this.sigPad = ref;
            }}
            canvasProps={{
              className: this.state.background ? "sigCanvas bg" : "sigCanvas",
            }}
            onBegin={this.startSignature}
            onEnd={this.saveSignature}
          />
        </div>
        <div className="row">
          <p className="clear-btn-signature" onClick={this.clearignature}>
            {clearBtn}
          </p>
        </div>
        <footer className={loading ? "loading" : ""}>
          <div className="row">
            <Grid container>
              <Grid container item xs justify="flex-start">
                <Link
                  to="/information-review"
                  onClick={(e) => {
                    e.preventDefault();
                    this.sendToApi("/information-review");
                  }}
                  className="prev-btn"
                >
                  <div className="footer-loader-wrapper left">
                    {loading ? <Loader /> : <ArrowBackIosIcon />}
                    <span>{prevBtn}</span>
                  </div>
                </Link>
              </Grid>
              <Grid container item xs justify="center">
                <Link
                  to="/logout"
                  className="end-sesion-btn"
                  onClick={(e) => {
                    if (loading) {
                      e.preventDefault();
                    }
                  }}
                >
                  {" "}
                  {endSession}{" "}
                </Link>
              </Grid>
              <Grid container item xs justify="flex-end">
                {signature ? (
                  <Link
                    to="/general-info/step1"
                    onClick={(e) => {
                      e.preventDefault();
                      this.sendToApi("/general-info/step1");
                    }}
                    className="next-btn"
                  >
                    <div className="footer-loader-wrapper">
                      <span>{nextBtn}</span>

                      {loading ? <Loader /> : <ArrowForwardIosIcon />}
                    </div>
                  </Link>
                ) : (
                  <Link
                    to="/general-info/step1"
                    onClick={(event) => event.preventDefault()}
                    className="disabled next-btn"
                  >
                    <div className="footer-loader-wrapper">
                      <span>{nextBtn}</span>

                      {loading ? <Loader /> : <ArrowForwardIosIcon />}
                    </div>
                  </Link>
                )}
              </Grid>
            </Grid>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Signature;
