import React, { Component } from 'react'; 

import CheckboxDropdownHousehold from '../../Components/CheckboxDropdownHousehold'
import CheckBoxRowTemplateHousehold from '../../Components/CheckBoxRowTemplateHousehold'
import AditionalInputsTemplateHousehold from '../../Components/AditionalInputsTemplateHousehold'

class TaxReturn extends Component { 
    handleCheckboxDropdownHousehold = (state, value, round) =>{this.props.handleCheckboxDropdownHousehold(state, value, round)}  
    handleIncomeChangeHousehold=(event,stateForUpdate, round) => {this.props.handleIncomeChangeHousehold(event,stateForUpdate, round)} 
    
    handleRowClickHousehold = (inputName,stateForUpdate,val, round) => {this.props.handleRowClickHousehold(inputName,stateForUpdate,val, round)}

    addRowHousehold = (event, stateObjName, round) => {this.props.addRowHousehold(event, stateObjName,round)}
    handleNameHousehold = (event, index,stateObjName, round) =>{this.props.handleNameHousehold(event, index, stateObjName,round)}
    handleAmountHousehold = (event, index, stateObjName, round) =>{ this.props.handleAmountHousehold(event, index, stateObjName, round)}
    removeRowHousehold = (index,stateObjName,round) => {this.props.removeRowHousehold(index,stateObjName,round)}


    render() { 
        const {landData, stepData, stateData} =  this.props.data 
    
        const {alimony, studentLoanInterest, childcare, monthyAmountText, monthyAmountPlaceholder, others,othersPlaceholder, addText} = landData; 
        const {stateElement, stateObjName, round} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal, expenseList} = stateData[round].financialInfo;
        
        let propsObj = {landData, stepData, stateData}
         
        return ( 
            <React.Fragment>  
                <CheckboxDropdownHousehold 
                    data = {propsObj} 
                    handleCheckboxDropdownHousehold = {this.handleCheckboxDropdownHousehold} />
                    {stateElementVal=='yes' &&
                        <div>
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={alimony}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='alimony'
                                inputValue = {expenseList.alimony} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />     
                             
                            <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={childcare}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='childcare'
                                inputValue = {expenseList.childcare} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />   
                             <CheckBoxRowTemplateHousehold 
                                round = {round}
                                text={studentLoanInterest}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='studentLoanInterest'
                                inputValue = {expenseList.studentLoanInterest} 
                                stateForUpdate = 'expenseList'
                                handleIncomeChangeHousehold = {this.handleIncomeChangeHousehold}
                                handleRowClickHousehold ={this.handleRowClickHousehold}
                            />   
                        </div>      
                    }
                    <AditionalInputsTemplateHousehold
                        round = {round}
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal  = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={others}
                        namePlaceholder={othersPlaceholder} 
                        amountLabel ={monthyAmountText}
                        monthyAmountPlaceholder ={monthyAmountPlaceholder} 
                        addText = {addText}
                        addRowHousehold = {this.addRowHousehold}
                        handleNameHousehold = {this.handleNameHousehold}
                        handleAmountHousehold = {this.handleAmountHousehold}
                        removeRowHousehold = {this.removeRowHousehold} 
                    />
               
            </React.Fragment> 
        );
    }
}
 
export default TaxReturn;