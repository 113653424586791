import React, { Component} from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import InputMask from "react-input-mask";

import {ReactComponent as CheckboxRound} from '../../styles/imgs/checkbox-round.svg';
import {ReactComponent as CheckboxRoundFull} from '../../styles/imgs/checkbox-round-full.svg';
import {ReactComponent as EditIcon} from '../../styles/imgs/editIcon.svg';

import {ReactComponent as MileStone1} from '../../styles/imgs/1-milestone.svg'; 
import {ReactComponent as MileStone2} from '../../styles/imgs/2-milestone.svg'; 
import {ReactComponent as MileStone3} from '../../styles/imgs/3-milestone.svg'; 
import {ReactComponent as MileStone4} from '../../styles/imgs/4-milestone.svg'; 
import {ReactComponent as MileStoneLine} from '../../styles/imgs/milestone-line.svg'; 

import {ReactComponent as WebsiteIcon} from "../../styles/imgs/website-icon.svg" 

import {ReactComponent as PhoneIcon} from '../../styles/imgs/phoneIcon.svg'; 
import {ReactComponent as EmailIcon} from '../../styles/imgs/emaiIcon.svg'; 

class Contacts extends Component { 

    handleChangeContacts = (event) =>{this.props.handleChangeContacts(event)}
    handleRowClickContacts = (inputName,stateForUpdate,val) => {this.props.handleRowClickContacts(inputName,stateForUpdate,val)}

    render() { 

        const {landData, stepData, stateData} =  this.props.data; 
        const {sectionTitle, emailLabel,emailLink,domainLink, phoneLabel, textMsgLabel, motivationText, aditionalInfo, phoneNumberText, emailText, domainText} = landData;  
        const {email, phone, message} = stateData;  
        return ( 
            <React.Fragment>
                <div className="row">
                   <h3 className="contact-title padding-tb-eq-b border">{sectionTitle}</h3>
                </div>
                <div className="container">
                    <Grid container className="own-checkbox">
                        <Grid container className='border padding-l focus-input'>
                            <Grid item container xs alignItems="center" tabIndex="0" justify="flex-start"  className="round-icon-w">
                                <CheckboxRound className={email ? 'hidden':''}/>
                                <CheckboxRoundFull onClick={()=>this.handleRowClickContacts('email', '')} className={email ? 'correctIcon':'hidden'}/>
                            </Grid> 
                            <Grid item xs={10} sm={10}>
                                <TextField 
                                    label={emailLabel}
                                    onChange={this.handleChangeContacts}
                                    name="email" 
                                    className={'full-width padding-tb address-input'}
                                    type="text" 
                                    value={email}
                                />
                            </Grid>
                            <Grid item container xs alignItems="center" justify="flex-start">
                                <EditIcon/>  
                            </Grid> 
                        </Grid>  
                    </Grid>  
                    <Grid container className="own-checkbox">
                        <Grid container className='border padding-l focus-input'>
                            <Grid item container xs alignItems="center" tabIndex="0" justify="flex-start"  className="round-icon-w">
                                <CheckboxRound className={phone ? 'hidden':''}/>
                                <CheckboxRoundFull onClick={()=>this.handleRowClickContacts('phone', '')} className={phone ? 'correctIcon':'hidden'}/>
                            </Grid> 
                            <Grid item xs={10} sm={10}>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={phone}
                                    onChange={this.handleChangeContacts}
                                    >
                                    {() => <TextField
                                            label={phoneLabel}
                                            onChange={this.handleChangeContacts}
                                            name="phone" 
                                            className={'full-width padding-tb address-input'}
                                            type="text" 
                                            value={phone}
                                    />} 
                                 </InputMask>  
                            </Grid>
                            <Grid item container xs alignItems="center" justify="flex-start">
                                <EditIcon/>  
                            </Grid> 
                        </Grid>  
                    </Grid>  
                    <Grid container className="own-checkbox">
                        <Grid container className='border-tb padding-l focus-input'>
                            <Grid item container xs alignItems="center" tabIndex="0" justify="flex-start"  className="round-icon-w">
                                <CheckboxRound className={ message ? 'hidden':''}/>
                                <CheckboxRoundFull onClick={()=>this.handleRowClickContacts('message', '')} className={message ? 'correctIcon':'hidden'}/>
                            </Grid> 
                            <Grid item xs={10} sm={10}>
                                <InputMask
                                        mask="(999) 999-9999"
                                        value={message}
                                        onChange={this.handleChangeContacts}
                                        >
                                        {() => <TextField
                                                label={textMsgLabel}
                                                onChange={this.handleChangeContacts}
                                                name="message" 
                                                className={'full-width padding-tb address-input'}
                                                type="text" 
                                                value={message}
                                        />} 
                                </InputMask>  
                            </Grid>
                            <Grid item container xs alignItems="center" justify="flex-start">
                                <EditIcon/>  
                            </Grid> 
                        </Grid>  
                    </Grid>  
                </div>
                <div className="row eq">
                    <h3 className="form-title text-center contacts-title">{motivationText}</h3> 
                    <ul className="contacts-box contacts">
                        <li>
                            <p><PhoneIcon/> {phoneNumberText}</p>
                        </li>
                        <li>
                            <p><EmailIcon/> <a href={"mailto:"+emailLink}>{emailText}</a></p>
                        </li>
                        <li>
                            <p><WebsiteIcon/><a href={domainLink}>{domainText}</a></p>
                        </li>
                    </ul> 
                </div>
                <div className="row eq"> 
                    <div className="mileStone-wrapper contacts-page"> 
                        <div className="steps-wrapper">
                            <MileStone1 className='correctIcon'/>
                            <MileStoneLine className='correctIcon'/>
                            <MileStone2 className='correctIcon'/>
                            <MileStoneLine className='correctIcon'/>
                            <MileStone3 className='correctIcon'/>
                            <MileStoneLine className='correctIcon'/>
                            <MileStone4 className='correctIcon'/>
                        </div>
                        <p className="aditionalInfo">{aditionalInfo}</p>
                    </div>
                </div>
            </React.Fragment> 
        );
    }
}
 
export default Contacts;