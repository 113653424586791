import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';

import {ReactComponent as MileStoneIcon} from '../styles/imgs/mileStoneIcon.svg'; 
import {ReactComponent as MileStone1} from '../styles/imgs/1-milestone.svg'; 
import {ReactComponent as MileStone2} from '../styles/imgs/2-milestone.svg'; 
import {ReactComponent as MileStone3} from '../styles/imgs/3-milestone.svg'; 
import {ReactComponent as MileStone4} from '../styles/imgs/4-milestone.svg'; 
import {ReactComponent as MileStoneLine} from '../styles/imgs/milestone-line.svg'; 

import Logo from "../Components/Logo"
import StepsCounter from "../Components/StepsCounter"

class Milestone extends Component {

    goToNextStep = () => {  
        const {history, stepData, globalStateData, personData} = this.props.data;
        const {nextLink, checkFormembers, milestone} =stepData;    

        if(checkFormembers){
            const {child, dependants, spouse} = globalStateData.generalInfo.familyMembers; 
            if( child || dependants || spouse){
                history.push(nextLink) 
            }else{
                history.push('/documents/step1')
            }
        }else if (milestone==1){
            if((new Date().getFullYear()-personData.birthday.year)<=12){
                history.push('/financial-info/step5')
            }else{ 
                history.push(nextLink) 
            } 
        }
        else{
            history.push(nextLink) 
        } 
    }

    render() { 
        const {landData, stepData, stateData} =  this.props.data; 
        const {mainTitle, intoText, motivationText, callToActionText, aditionalInfo} = landData; 
        const {stepCounter, milestone, nextLink} = stepData;   
        
        return ( 
            <React.Fragment>
            
            <div className="row">
                <div className="mileStone-wrapper border">
                    <div onClick = {this.goToNextStep} className="cursor">
                        <MileStoneIcon className="mainIcon"/>
                        <h6>{motivationText}</h6>
                        <h6 className="call-to-action">  
                            {callToActionText}  
                        </h6>
                        <div className="steps-wrapper">
                            <MileStone1 className={milestone>=1 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=2 ? 'correctIcon':'')}/>
                            <MileStone2 className={milestone>=2 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=3 ? 'correctIcon':'')}/>
                            <MileStone3 className={milestone>=3 ? 'correctIcon':''}/>
                            <MileStoneLine className={"line " + ( milestone>=4 ? 'correctIcon':'')}/>
                            <MileStone4 className={milestone>=4 ? 'correctIcon':''}/>
                        </div>
                        <p className="aditionalInfo">{aditionalInfo}</p>
                    </div>
                </div>
            </div>
            </React.Fragment>
         );
    }
}
 
export default Milestone;