import React, { Component } from "react";

import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { ReactComponent as ThankyouIcon } from "../styles/imgs/thankyouIcon.svg";
import { ReactComponent as WebsiteIcon } from "../styles/imgs/website-icon.svg";
import { ReactComponent as PhoneIcon } from "../styles/imgs/phoneIcon.svg";
import { ReactComponent as EmailIcon } from "../styles/imgs/emaiIcon.svg";

import Logo from "./../Components/Logo";
import SiteLink from "../Components/SiteLink";

class Thankyou extends Component {
  endSession = () => {
    if (sessionStorage.getItem("paramsId")) {
      this.props.history.push(
        "/screen/" + JSON.parse(sessionStorage.getItem("paramsId"))
      );
    } else {
      this.props.history.push("/");
    }
    this.props.endSession();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {
      mainTitle,
      intoText,
      motivationText,
      phoneNumberText,
      emailText,
      emailLink,
      domainLink,
      domainText,
      logoutText,
      siteName,
    } = this.props.landData || {};
    const { altLang, logoUrl, setLangCode, languageCode } = this.props;
    return (
      <React.Fragment>
        <div className="bg-blue thankyou" onClick={this.endSession}>
          <header>
            <div className="row">
              <Grid container spacing={0} alignItems="center">
                <Logo
                  setLangCode={setLangCode}
                  languageCode={languageCode}
                  altLang={altLang}
                  logoUrl={logoUrl}
                />
                <SiteLink data={siteName} />
              </Grid>
            </div>
          </header>
          <div className="row">
            <h2 className="section-title full-width">{mainTitle}</h2>
            <p className="text-intro">{intoText}</p>
          </div>

          <div className="main-icon">
            <ThankyouIcon></ThankyouIcon>
          </div>
          <h3 className="form-title text-center">{motivationText}</h3>

          <div className="row eq">
            <ul className="contacts-box contacts">
              {phoneNumberText && (
                <li>
                  <p>
                    <PhoneIcon /> {phoneNumberText}
                  </p>
                </li>
              )}
              {emailLink && (
                <li>
                  <p>
                    <EmailIcon />{" "}
                    <a href={"mailto:" + emailLink}>{emailText}</a>
                  </p>
                </li>
              )}
              {domainLink && (
                <li>
                  <p>
                    <WebsiteIcon />
                    <a href={domainLink}>{domainText}</a>
                  </p>
                </li>
              )}
            </ul>
          </div>
          <p className="footertext">{logoutText}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default Thankyou;
