// var MonthsArray =  ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

var MonthsArray = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
export default MonthsArray;
