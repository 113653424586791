import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import TextField from '@material-ui/core/TextField';

import {ReactComponent as CheckboxRound} from '../styles/imgs/checkbox-round.svg';
import {ReactComponent as CheckboxRoundFull} from '../styles/imgs/checkbox-round-full.svg';

class ChecBoxRowTemplate extends Component {
    handleIncomeChangeHousehold = (event, stateForUpdate, round) => {
        this.props.handleIncomeChangeHousehold(event, stateForUpdate, round)
    }

    handleRowClickHousehold = (inputName,stateForUpdate, val, round) => {this.props.handleRowClickHousehold(inputName,stateForUpdate, val, round)}

    render() { 
        const { text, monthyAmountText, monthyAmountPlaceholder, inputName, inputValue , stateForUpdate, round} =this.props;

        
        
        return ( 
            <div className="row border-b own-checkbox"> 
                <Grid container spacing={0} > 
                    <Grid container item xs={1} alignItems="center" tabIndex="0" justify="center" className="round-icon-w">
                        <CheckboxRound 
                        onClick={()=>this.handleRowClickHousehold(inputName, stateForUpdate, '0', round)} 
                        className={inputValue  ? 'hidden':''}/>
                        <CheckboxRoundFull 
                        onClick={()=>this.handleRowClickHousehold(inputName, stateForUpdate, '', round)}
                        className={inputValue ? 'correctIcon':'hidden'}/>
                    </Grid>  
                    <Grid container item xs={5} alignItems="center"> 
                        <p className='checkbox-title'> {text}</p>
                    </Grid>
                    <Grid container item xs={6} justify="center">
                        <TextField   
                            label={monthyAmountText}
                            placeholder={monthyAmountPlaceholder}
                            onChange={(event)=>this.handleIncomeChangeHousehold(event, stateForUpdate, round)}
                            name={inputName}
                            className={'full-width padding-tb'}
                            type="number" 
                            validators={['required']}
                            value={inputValue} 
                            InputProps={{ 
                                startAdornment: (
                                    <InputAdornment position="start">
                                    $
                                    </InputAdornment>
                                ),
                            }}
                        /> 
                    </Grid>    
                </Grid>   
            </div>
         );
    }
}
 
export default ChecBoxRowTemplate;