import React, { Component } from 'react'; 

import CheckboxDropdown from '../../Components/CheckboxDropdown'
import ChecBoxRowTemplate from '../../Components/CheckBoxRowTemplate'
import AditionalInputsTemplate from '../../Components/AditionalInputsTemplate'

class Income extends Component { 
    handleCheckboxDropdown = (state, value) =>{this.props.handleCheckboxDropdown(state, value)}  
    handleIncomeChange=(event,stateForUpdate) => {this.props.handleIncomeChange(event,stateForUpdate)}
   
    handleRowClick = (inputName,stateForUpdate,val) => {this.props.handleRowClick(inputName,stateForUpdate,val)}
    
    addRow = (event, stateObjName) => {this.props.addRow(event, stateObjName)}
    handleName = (event, index,stateObjName) =>{this.props.handleName(event, index, stateObjName)}
    handleAmount = (event, index,stateObjName) =>{ this.props.handleAmount(event, index, stateObjName)}
    removeRow = (index,stateObjName) => {this.props.removeRow(index,stateObjName)}


    render() { 
        const {landData, stepData, stateData} =  this.props.data 
        
        const {unemployment,socialSecurity,pensions,retirementAccounts,alimony,childSupport, monthyAmountText, addText, monthyAmountPlaceholder, others,othersPlaceholder, employerNamePlacehoder} = landData; 
        const {stateElement, stateObjName} = stepData; 
        let {[stateObjName]:stateObj, [stateElement]:stateElementVal, incomeList} = stateData;    
 
        let propsObj = {landData, stepData, stateData}
        return ( 
            <React.Fragment>  
                <CheckboxDropdown 
                    data = {propsObj} 
                    handleCheckboxDropdown = {this.handleCheckboxDropdown} />
                    {stateElementVal=='yes' &&
                        <div>
                            <ChecBoxRowTemplate 
                                text={pensions}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='pensions'
                                inputValue = {incomeList.pensions} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            /> 
                            <ChecBoxRowTemplate 
                                text={socialSecurity}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='socialSecurity'
                                inputValue = {incomeList.socialSecurity} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            />  
                             <ChecBoxRowTemplate 
                                text={retirementAccounts}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='retirementAccounts'
                                inputValue = {incomeList.retirementAccounts} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            />
                             <ChecBoxRowTemplate 
                                text={unemployment}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='unemployment'
                                inputValue = {incomeList.unemployment} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                             
                            /> 
                            <ChecBoxRowTemplate 
                                text={alimony}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='alimony'
                                inputValue = {incomeList.alimony} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            />
                            <ChecBoxRowTemplate 
                                text={childSupport}
                                monthyAmountText={monthyAmountText}
                                monthyAmountPlaceholder={monthyAmountPlaceholder}
                                inputName='childSupport'
                                inputValue = {incomeList.childSupport} 
                                stateForUpdate = 'incomeList'
                                handleIncomeChange = {this.handleIncomeChange}
                                handleRowClick = {this.handleRowClick}
                            /> 
                           
                        </div>      
                    }
                    <AditionalInputsTemplate
                        stateObj={stateObj}
                        stateObjName={stateObjName}
                        stateElementVal = {stateElementVal}
                        stateElement ={stateElement}
                        nameLabel={others}
                        namePlaceholder={othersPlaceholder} 
                        amountLabel ={monthyAmountText}
                        monthyAmountPlaceholder ={monthyAmountPlaceholder} 
                        addText = {addText}
                        addRow = {this.addRow}
                        handleName = {this.handleName}
                        handleAmount = {this.handleAmount}
                        removeRow = {this.removeRow} 
                    />
               
            </React.Fragment> 
        );
    }
}
 
export default Income;