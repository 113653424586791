import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AdvocatiaLogo from "../styles/imgs/advocatia_full.png";

const Logo = ({
  altLang,
  logoUrl,
  languageCode = "en",
  setLangCode = () => {},
}) => {
  const { Title, UseLogo } = altLang;

  return (
    <React.Fragment>
      <Grid container item spacing={0}>
        <div className="logo-wrapper">
          {/* <Link to='/logout' className="logo">{brandName} </Link> */}
          <div className="logo-inner-wrapper">
            <div className="lang-wrapper">
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
                className="select-field MuiInputBase-inputSelect"
                value={languageCode}
                onChange={(e) => setLangCode(e.target.value)}
                // displayEmpty
                // inputProps={{
                //   name: "month",
                // }}
              >
                <MenuItem value="en">
                  <div className="menu-item-wrapper">
                    <span>ENGLISH</span>
                  </div>
                </MenuItem>
                <MenuItem value="es">
                  <div className="menu-item-wrapper">
                    <span>ESPA&#209;OL</span>
                  </div>
                </MenuItem>
              </Select>
            </div>
            {UseLogo ? (
              <div className="logo-img">
                <img src={logoUrl} alt="" />
              </div>
            ) : (
              <div className="logo">{Title}</div>
            )}
          </div>
        </div>
        <div className="Powered-By">
          Powered By
          <img
            src={AdvocatiaLogo}
            alt="Advocatia Logo"
            style={{ width: "10rem" }}
          />
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default Logo;
